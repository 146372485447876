export default{
    actions: {
      
    },
    mutations: {
    addpostobv(state, newpost){
         state.postobv=newpost;
    },
    addpostimgobv(state, newpost){
         state.postimg=newpost;
    },
    addsedichkaobv(state){
      state.postobv.serdichka = !state.postobv.serdichka;
    }
    },
    state: {
        postobv: [],
        postimg:[]
    },
    getters:{
        getpostobv(state){
            return state.postobv
        }, 
        getpostimgobv(state){
            return state.postimg
        }, 
    }
}