import VueRouter from 'vue-router'
import Home from '../page/Home'
import Podat from '../page/Podat'
import login from '../page/login'
import cbn from '../page/cbn'
import edit from '../page/edit'
import k from '../page/k'
import pk from '../page/pk'
import obv from '../page/obv'
import favorites from '../page/favorites'
import img from '../page/img'
import settingsearch from '../page/settingsearch'
import message from '../page/message'
import chat from '../page/chat'
import bezopasnost from '../page/bezopasnost'
import pravila from '../page/pravila'
export default new VueRouter({
  mode: 'history',
   routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/podat',
      name: 'Podat',
      component: Podat
    },
    {
      path: '/favorites',
      name: 'favorites',
      component: favorites
    },
    {
      path: '/message',
      name: 'message',
      component: message
    },
    {
      path: '/chat',
      name: 'chat',
      component: chat
    },
    {
      path: '/login',
      name: 'login',
      component: login,
      beforeEnter: ( to, from, next )=>{
        if(!localStorage.getItem("token")){
          next();
        }else{
          next({name: 'cbn'})
        }
       }
    },
    {
      path: '/cbn',
      name: 'cbn',
      component: cbn
    },
    {
      path: '/pravila',
      name: 'pravila',
      component: pravila
    },
    {
      path: '/bezopasnost',
      name: 'bezopasnost',
      component: bezopasnost
    },
    {
      path: '/settingsearch',
      name: 'settingsearch',
      component: settingsearch
    },
    {
      path: '/edit/:id',
      name: 'edit',
      component: edit
    },
    {
      path: '/k/:id',
      name: 'k',
      component: k
    },
    {
      path: '/pk/:id',
      name: 'pk',
      component: pk
    },
    {
    path: '/obv/:id',
    name: 'obv',
    component: obv
    },
    {
    path: '/img/:id',
    name: 'img',
    component: img
    },
    {
      path: '*',
      name: 'Home',
      component: Home
    }
      
    
  ]
});