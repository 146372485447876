<template>
<div>
<div>
<div class = "rubrika">
<table border="0" width="100%">
<tr>
<td>
<router-link :to="{name:'k', params:{id:1}}">
<div>
<table width="100%"><tr>
<td><img src="https://imgagros.ru/storage/img/111111111/11111/frukti_mobil.png"/></td>
<td width="100%"><span>Фрукты</span></td>
</tr></table>
</div>
</router-link>
</td>
<td>
<router-link :to="{name:'k', params:{id:2}}">
<div>
<table width="100%"><tr>
<td><img src="https://imgagros.ru/storage/img/111111111/11111/ovoshi_mobil.png"/></td>
<td width="100%"><span>Овощи</span></td>
</tr></table>
</div>
</router-link>
</td>
<td>
<router-link :to="{name:'k', params:{id:3}}">
<div>
<table width="100%"><tr>
<td><img src="https://imgagros.ru/storage/img/111111111/11111/zerno_mobil.png"/></td>
<td width="100%"><span>Зерно</span></td>
</tr></table>
</div>
</router-link>
</td>
<td>
<router-link :to="{name:'k', params:{id:4}}">
<div>
<table width="100%"><tr>
<td><img src="https://imgagros.ru/storage/img/111111111/11111/sad_i_rastenie_mobil.png"/></td>
<td width="100%"><span>Сад и <br/>растения</span></td>
</tr></table>
</div>
</router-link>
</td>
<td>
<router-link :to="{name:'k', params:{id:5}}">
<div>
<table width="100%"><tr>
<td><img src="https://imgagros.ru/storage/img/111111111/11111/zhivotnovodstvo_mobil.png"/></td>
<td width="100%"><span>Животноводство</span></td>
</tr></table>
</div>
</router-link>
</td>
<td>
<router-link :to="{name:'k', params:{id:6}}">
<div>
<table width="100%"><tr>
<td><img src="https://imgagros.ru/storage/img/111111111/11111/producti_zhivot_mobil.png"/></td>
<td width="100%"><span>Продукты <br/>животноводства</span></td>
</tr></table>
</div>
</router-link>
</td>
<td>
<router-link :to="{name:'k', params:{id:7}}">
<div>
<table width="100%"><tr>
<td><img src="https://imgagros.ru/storage/img/111111111/11111/rabota_i_usluga_mobil.png"/></td>
<td width="100%"><span>Работа и <br/>услуги</span></td>
</tr></table>
</div>
</router-link>
</td>
<td>
<router-link :to="{name:'k', params:{id:8}}">
<div>
<table width="100%"><tr>
<td><img src="https://imgagros.ru/storage/img/111111111/11111/texnika_i_oborudovanie_mobil.png"/></td>
<td width="100%"><span>Техника и <br/>оборудование</span></td>
</tr></table>
</div>
</router-link>
</td>
<td>
<router-link :to="{name:'k', params:{id:9}}">
<div>
<table width="100%"><tr>
<td><img src="https://imgagros.ru/storage/img/111111111/11111/udobreniya_i_agroximiya_mobil.png"/></td>
<td width="100%"><span>Удобрения и <br/>агрохимия</span></td>
</tr></table>
</div>
</router-link>
</td>
</tr>
</table>
</div>
</div>
</div>
</template>
<script>
export default{
}
</script>
<style scoped>
.rubrika span{
text-align:left;
font-size:20px;
color:#000000;
}
.rubrika a{
text-decoration:none;
}
.rubrika {
background-color: #f5f5f5; 
margin-top:8px;
padding-top:7px;
height:5em;
width:100%;
overflow-x: auto;
overflow-y: auto;
white-space: nowrap;
}
.rubrika  div {
background-color: #ffffff;
border-radius: 10px;
margin: 3px 6px 3px -2px;
height: 53px;
padding:3px;

}
.rubrika img{
width:50px;
}
.rubrika label{
color:#666666;
}

</style>
