<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-body">
            <div class="body">
             <img src="https://imgagros.ru/storage/img/111111111/11111/logoagros.jpg"/><br/>
              <h2>Новая версия</h2>
              <div><span>
               Эта версия приложения устарела. <br/>
               Чтобы продолжить, пожалуйста
               установите последню версию с 
               новыми возможностями
              </span></div>
             <a :href="props_app_link"><button class="btns">ОБНОВИТЬ</button></a>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    props_app_link: String,
  },
};
</script>
<style scoped>
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  z-index: 2000;
  margin: 0 auto;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.body {
 margin:50px;
 text-align: center;
}
.body img {
width:150px;
}
.body div {
 font-size: 20px;
 color:#333;
 margin:10px;
 text-align: left;
}
.body h3 {
 margin:10px;
}
.modal-container {
  width: 100%;
  height: 100%;
  margin: 0px auto;
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-body {
  margin: 40;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
