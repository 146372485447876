<template>
    <div>
     <header3 :title="title"/>
     <paddingheader />
      <div class="content_favorites" v-if="!displaysuccessfavorites">
       <div class="content_kart">
         
          <Kartfavorites  :obvs="getpostfavorites" @emitfavorites="emitfavorites" /> 
         
          </div>
      </div>
      <div v-else align="center" class="izprannoe">
          <div>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--jam" width="100" height="100" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
               <path d="M11.293 5.55l.707.708l.707-.707a5 5 0 1 1 7.071 7.071l-7.07 7.071a1 1 0 0 1-1.415 0l-7.071-7.071a5 5 0 1 1 7.07-7.071z" fill="currentColor"></path></svg>
          </div>
          <span>Вы еще не добавили ни одного объявления в Избранное</span>
      </div>
       <div v-if="loading" align="center">
           <div class="spin">
           <Spinner />
           </div>
        </div>
       <noconnection :propsmodalalert="propsmodalalert" @emitnoconnection="emitnoconnection" />
    <paddingheader />
    <Footermenu />
    </div>
</template>
<script>
import axios from 'axios';
import noconnection from '../blocks/noconnection'
import header3 from '../blocks/header3';
import paddingheader from '../blocks/paddingheader';
import Footermenu from "../blocks/footermenu";
import Spinner from '../blocks/spinner';
import Kartfavorites from '../blocks/kartfavorites';
export default{
components:{
    Spinner, header3, paddingheader, Footermenu, noconnection, Kartfavorites

  },
data() {
    return {
        title:"Избранное",
        loading:false,
        propsmodalalert:false,
        displaysuccessfavorites:false,
        
    }
  },
created(){
    if(!localStorage.getItem("token")){
      if(localStorage.getItem("login")){
        localStorage.removeItem("login");
        this.$router.go(-1);
      }
      else{
       this.$router.push({name:"login"});
      }
    } 
    else{
      axios.defaults.headers.common["Authorization"] = "Bearer "+localStorage.getItem("token"); 
     }  
},
methods:{
 emitnoconnection(){
   this.propsmodalalert=false;
   this.getfavorites();
 },
 emitfavorites(kod){
if(localStorage.getItem("token")){
  
    axios({
           method: 'post',
            url: 'https://agros.tj/api/addserdichka',
            data: {
            kod:kod,
            token:localStorage.getItem("token"),
           }
          })
        .then(res=>{
            if(res.data.status){
               console.log("true");
            }
        });
   }
   else{
    this.$router.push({name:"login"}); 
   }
 },
 clearvuex(){
     this.$store.commit("addpostobv",new Array);
     this.$store.commit("addpostimgobv",new Array);
    },
 getfavorites(){
   if(localStorage.getItem("token")){
     this.loading=true;
      try{
         axios({
           method: 'post',
            url: 'https://agros.tj/api/getfavorites',
            data: {
            token: localStorage.getItem("token"),
           }
          })
        .then(res=>{
          if(!res.data.message){
            if(res.data.status){
               this.displaysuccessfavorites=false,
               this.$store.commit("addpostfavorites",res.data.obvs);
               this.loading=false;
            }
            else{
             this.displaysuccessfavorites=true;
              this.loading=false;
            }
          }
           else{
             localStorage.removeItem("token");
             this.$router.go();
           } 
        }).catch(error => {
        if (!error.response) {
            this.propsmodalalert=true;
        }
      })
     } 
     catch (e){alert ("Error");}}
   },
},
 computed: {
   getpostfavorites(){
    return this.$store.getters.getpostfavorites;
   }
},
mounted(){
    this.clearvuex();
    if(!this.getpostfavorites.lenght) this.getfavorites();
    this.$metrika.hit("favorites");
    }
    
}
</script>
<style scoped>
.content_favorites{
    background-color: #f5f5f5;
    margin-top:10px;
}
.izprannoe {
    margin-top:40px;
    margin-bottom: 20px;
}
.izprannoe div{
color:#666;
}
.izprannoe span{
color:#666;
font-size:20px;
}

</style>
