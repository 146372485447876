<template>
 <div class="margin5">
  <header3 :title="title"/>
  <paddingheader />
  <br/>
  <div v-if="display==1">
  <div class="content_tel">
      <h4 class="div_placeholder">Ваш номер телефона:</h4>
      <div>
          <table>
              <tr>
                  <td>
                    <div class="tel_992" >+992</div>
                  </td>
                  <td width="100%">
                      <input type="number" ref="number" @keyup="keyuptel" v-model.trim ="tel" class="inputlogin" />
                  </td>
              </tr>
          </table>
      </div>
  </div>
  <div class="success">{{textsuccesstel}}</div>
  <div class="content_pravila">
  <div class="checkbox">
      <input class="custom-checkbox" @change="clickpravila" v-model="pravila"  type="checkbox" id="pravila" name="pravila" value="indigo">
      <label for="pravila">Я согласен на обработку моих персональных данных, соглашаюсь с правилами сайта и подтверждаю, что я старше 18 лет.</label>
  <router-link class="pp" to="/pravila">Правила сайта</router-link>
  </div>
  </div>
  <div class="success_pravila" v-if="textsuccesspravila">Вы должны согласиться с <router-link to="/pravila">правилами сайта</router-link>  и подтвердить, что вы старше 18 лет и согласны на обработку ваших персональных данных."</div>
  <br/>
  <br/>
  <br/>
  <div align="center">
  <button class="btns"  @click="f_tel">ПРОДОЛЖИТЬ</button>
  </div>
  <br/>
  <br/>
  </div>



  <div v-if="display==2" >
  <div class="content_tel">
      <h4 class="div_placeholder">Введите код из СМС, который мы вам отправили ({{itimer}})</h4>
      <br/>
      <div>
        <input type="number" autofocus @keyup="keyupkod" v-model.trim ="kod" class="inputlogin" />
      </div>
  </div>
  <div class="success">{{textsuccesskod}}</div>
  <br/>
  <div align="center">
  <button class="btns"  @click="f_kod">ПРОДОЛЖИТЬ</button>
  </div>
  <br/>
  <div v-if="!itimer" class="otp_povtor">
      Если вы не получили СМС
      с кодом - <div @click="f_tel">Отправить код повторно</div>
  </div>
  <br/>
  <br/>
  </div>

 <div v-if="display==3" >
  <div class="content_tel">
      <h4 class="div_placeholder">Ваш имя:</h4>
      <div>
        <input type="text" @keyup="keyupname" v-model.trim="name" class="inputlogin" />
      </div>
  </div>
  <div class="success">{{textsuccessname}}</div>
  <br/>
  <div align="center">
  <button class="btns"  @click="f_name">ПРОДОЛЖИТЬ</button>
  </div>
   <br/>
  <br/>
  </div>
  <div align="center">
  <Spinner v-if="display==0" />
  </div>
  <noconnection :propsmodalalert="propsmodalalert" @emitnoconnection="emitnoconnection" />
  </div> 
</template>

<script>
import noconnection from '../blocks/noconnection';
import Spinner from '../blocks/spinner';
import header3 from '../blocks/header3';
import paddingheader from '../blocks/paddingheader';
import axios from 'axios'
export default {
 
  components:{
    Spinner, header3, paddingheader, noconnection 
  },
  data() {
    return {
        title:"Вход",
        pravila:false,
        tel:"",
        kod:"",
        name:"",
        display:1,
        textsuccesstel:"",
        textsuccesspravila:false,
        textsuccesskod:"",
        textsuccessname:"",
        i:1,
        timer_sms:"",
        itimer:0,
        propsmodalalert:false,



    }
  },

  methods: {
   f_tel(){
     if(this.tel.length!=9){
         this.textsuccesstel="Телефон не заполнен или неверный";
     }
     else if(!this.pravila){
         this.textsuccesspravila=true;
     }
     else {
         this.display=0;
     try{
         axios({
           method: 'post',
            url: 'https://agros.tj/api/tel',
            data: {
            tel: this.tel,
           }
          })
        .then(res=>{
            if(res.data.status){
               this.display=2;
               this.i=1; 
                this.timer_sms = setInterval(() =>{
                this.itimer=100-this.i; 
                if (this.i==100) this.unserttk();
                this.i++;
                }, 1000);
            }
            else{
               this.textsuccesstel="Телефон не заполнен или неверный";
               this.display=1;
            }
        }).catch(error => {
        if (!error.response) {
            this.display=1;
            this.propsmodalalert=true;
        }
        }) 
     } 
     catch (e){
         this.display=1;
     }

     }
    },
    emitnoconnection(){
    if(this.display==1){
      this.propsmodalalert=false;
       this.f_tel();
    }
    if(this.display==2){
      this.propsmodalalert=false;
       this.f_kod();
    }
    if(this.display==3){
      this.propsmodalalert=false;
       this.f_name();
    }
    
   },
    keyuptel(){
        if(this.tel.length>1){
            this.textsuccesstel="";
        }   
    },
    keyupkod(){
        if(this.kod.length > 1){
            this.textsuccesskod="";
        }   
    },
    keyupname(){
        if(this.name.length > 2){
            this.textsuccessname="";
        }   
    },
    unserttk(){
    clearTimeout(this.timer_sms);
    },
    clickpravila(){
        if(this.pravila){
            this.textsuccesspravila=false;
        }
    },
    f_kod(){
     if(this.kod.length<1 || this.kod.length>6 ){
         this.textsuccesskod="Неверный код";
     }
     else {
         this.display=0;
         try{
         axios({
           method: 'post',
            url: 'https://agros.tj/api/kod',
            data: {
            tel: this.tel,
            kod:this.kod,
           }
          })
        .then(res=>{
            if(res.data.status){
               if(!res.data.name){
                 this.display=3;
               }
               else{
                   localStorage.setItem (
                    "token",
                    res.data.user.api_token
                   );
                  localStorage.removeItem("login");
                   this.$router.go(-1);
     
               }
            }
            else{
             this.display=2;
             this.textsuccesskod="Неверный код";
            }
        }).catch(error => {
        if (!error.response) {
            this.display=2;
            this.propsmodalalert=true;
        }
        })
     } 
     catch (e){
         alert ("Error");
         this.display=2;
     }
       
     }
    },
    f_name(){
     if(this.name.length<3 || this.name.length>30 ){
         this.textsuccessname="Имя не заполнен или неверный";
     }
     else {
          this.display=0;
         try{
         axios({
           method: 'post',
            url: 'https://agros.tj/api/name',
            data: {
            tel: this.tel,
            kod:this.kod,
            name:this.name
           }
          })
        .then(res=>{
            if(res.data.status){
                  localStorage.setItem (
                    "token",
                    res.data.user.api_token
                   );
                   localStorage.removeItem("login");
                   this.$router.go(-1);
            }
            else{
             this.display=3;
            this.textsuccessname="Имя не заполнен или неверный";
            }
        }).catch(error => {
        if (!error.response) {
            this.display=3;
            this.propsmodalalert=true;
        }
        })
     } 
     catch (e){
         alert ("Error");
         this.display=3;
     }
     }
    },
    setFocus() {
        this.$refs.number.focus()  
    },

  }, 
  mounted(){
     this.setFocus();
     localStorage.setItem("login",1);
      this.$metrika.hit("login");
  },
};
</script>

<style scoped>
  
.div_placeholder {
    height: 28px;
    font-size: 21px;
    color:#000000;
    margin-left:3px;
}
label{
    font-size: 18px;
}
.pp{
margin-left:22px;
}
.otp_povtor{
    font-size: 20px;
    color:#000000; 
}
.otp_povtor div{
    font-size: 20px;
    color:green; 
}
.success {
  height: 16px;
  font-size: 16px;
  color:#c20404;
  margin-left:3px;
  margin-top: 3px;
}
.success_pravila {
  font-size: 15px;
  color:#c20404;
  margin-left:3px;
}
.tel_992{
    font-size: 22px;
}
.inputlogin{
  width:98%;
  border: 2px solid #ffffff;
  padding:5px 0px 5px 3px;
  font-size: 22px;
}
.content_tel{
  border: 2px solid #ffffff;
  border-bottom-color: #ff6600;   
}
</style>
