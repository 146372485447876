<template>
    <div class="content_dialogs">
     <header3 :title="title"/>
     <paddingheader />
     <br/>
       <div class="content_kart">  
          <Kartdialogs  :dialog="dialogs" /> 
        </div>
       <div v-if="loading" align="center">
           <div class="spin">
           <Spinner />
           </div>
        </div>
    <noconnection :propsmodalalert="propsmodalalert" @emitnoconnection="emitnoconnection" />
    <paddingheader />
    <Footermenu />
    </div>
</template>
<script>
import axios from 'axios';
import noconnection from '../blocks/noconnection'
import header3 from '../blocks/header3';
import paddingheader from '../blocks/paddingheader';
import Footermenu from "../blocks/footermenu";
import Spinner from '../blocks/spinner';
import Kartdialogs from '../blocks/kartdialogs';
export default{
components:{
    Spinner, header3, paddingheader, Footermenu, noconnection, Kartdialogs

  },
data() {
    return {
        title:"Сообщения",
        loading:false,
        propsmodalalert:false,
        
    }
  },
created(){
    if(!localStorage.getItem("token")){
      if(localStorage.getItem("login")){
        localStorage.removeItem("login");
        this.$router.go(-1);
      }
      else{
       this.$router.push({name:"login"});
      }
    }
    else{
      axios.defaults.headers.common["Authorization"] = "Bearer "+localStorage.getItem("token"); 
     }  
},
methods:{
 emitnoconnection(){
   this.propsmodalalert=false;
   this.getdialogs();
 },

 getdialogs(){
         this.loading=true;
         axios({
           method: 'post',
            url: 'https://agros.tj/api/getdialogs',
            data: {
            token: localStorage.getItem("token"),
           }
          })
        .then(res=>{
          if(!res.data.msg){
            if(res.data.status){
               this.$store.commit("adddialogs",res.data.dialogs);
               this.loading=false;
            }
            else{
              this.loading=false;
            }
          }
           else{
             localStorage.removeItem("token");
             this.$router.go();
           } 
        }).catch(error => {
        if (!error.response) {
            this.propsmodalalert=true;
        }
      })
 }    
},
 computed: {
   dialogs(){
    return this.$store.getters.getdialogs;
   },
   kodmessage(){
    return this.$store.getters.getkodmessage;
   },
},
mounted(){
   if(this.kodmessage){
       this.$router.push({name:"chat"}); 
    }  
    this.getdialogs();
    this.$store.commit("addunread",0);  
    this.$metrika.hit("message"); 
}
}
</script>
<style scoped>
.content_dialogs{
  background-color: #f5f5f5;
}

</style>
