<template>
 <div align="center" class="cont">
    <div class="contend_chat">
     <div class="content_head" align="center">
        <table  width="100%">
        <tr>
        <td align="left">
        <div onclick="javascript:history.back(); return false;" class="imgback">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--ep" width="30" height="30" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024">
        <path fill="currentColor" d="M685.248 104.704a64 64 0 0 1 0 90.496L368.448 512l316.8 316.8a64 64 0 0 1-90.496 90.496L232.704 557.248a64 64 0 0 1 0-90.496l362.048-362.048a64 64 0 0 1 90.496 0z"></path></svg></div>
        </td>
        <td align="left"  width="100%">
        <div ><div class="title_overflow"><span>{{name_user2}}</span>
        </div>
        <div>
        <router-link class="title_obv"  :to="{name:'obv', params:{id:id_obv}}">
            {{title_obv}}
        </router-link>
        </div>
        </div>
        </td>	   
        </tr>
        </table>
        </div>
     <paddingheader />
     <br/>
       <div class="content_kart">  
          <div  v-for="message in messages" :key="message.id">
               <div class="user"  v-if="message.sender">
                   <label>{{message.dt}}</label><br/>
                   <div>{{message.text_message}}</div>
                </div> 
                <div class="nouser" v-else>
                  <label>{{message.dt}}</label><br/>
                   <div>{{message.text_message}}</div>
                </div>  
          </div> 
        </div>
        <br/>
       <div v-if="loading" align="center">
           <div class="spin">
           <Spinner />
           </div>
        </div>
    <div class="content_sendmessage" align="center"> 
        <div class="divsend">
       <table>
           <tr>
               <td width="100%">
                 <div v-if="blockformtextarea">
                 <resizable-textarea ref="resize">
                  <textarea ref="textarea" rows="1" class="textareat" v-model.trim="text_send_message" placeholder="Ваше сообщение"></textarea>
                  </resizable-textarea>
                 </div>
               </td>
               <td><div @click="sendmessage()"><img src="../assets/image/ic_send_128_28719.png" ></div></td>
           </tr>
      </table> 
        </div>
    </div>
    <noconnection :propsmodalalert="propsmodalalert" @emitnoconnection="emitnoconnection" />
    <paddingheader />
    </div>
 </div>
</template>
<script>
import ResizableTextarea from '../blocks/ResizableTextarea'
import axios from 'axios';
import noconnection from '../blocks/noconnection'
import paddingheader from '../blocks/paddingheader';
import Spinner from '../blocks/spinner';
export default{
components:{
    Spinner,  paddingheader, noconnection,  ResizableTextarea

  },
data() {
    return {
        title:"dfdfgf",
        loading:false,
        propsmodalalert:false,
        text_send_message:"",
        send_text:"",
        id_obv:1,
        teluser2:"",
        title_obv:"",
        name_user2:"",
        messages:[],
        p:true,
        timer_interval:"",
        pauza:100,
        timer:"",
        pauza1:10,
        timer1:"",
        blockformtextarea:true,
    }
  },
created(){
    if(!localStorage.getItem("token")){
      if(localStorage.getItem("login")){
        localStorage.removeItem("login");
        this.$router.go(-1);
      }
      else{
       this.$router.push({name:"login"});
      }
    }
    else{
      axios.defaults.headers.common["Authorization"] = "Bearer "+localStorage.getItem("token"); 
     }  
},
methods:{
 emitnoconnection(){
   this.propsmodalalert=false;
   this.getmessages();
 },

 getmessages(){
      if(this.p)  this.loading=true,
         axios({
           method: 'post',
            url: 'https://agros.tj/api/getmessages',
            data: {
            idobv:this.id_obv,
            teluser2:this.teluser2,
            token: localStorage.getItem("token"),
           }
          })
        .then(res=>{
          if(!res.data.msg){
            if(res.data.status){
               if(res.data.messages){
                  this.messages=res.data.messages;
                }
               this.name_user2=res.data.obv.name;
               this.title_obv=res.data.obv.title;
               this.loading=false;
               this.p=false;
               clearTimeout(this.timer);
               this.timer = setTimeout ( () => {
               window.scrollTo(0, document.body.scrollHeight+100 || document.documentElement.scrollHeight+100);
             }, this.pauza);
            }
            else{
              this.loading=false;
              this.p=false;
            }
          }
           else{
            // localStorage.removeItem("token");
             this.$router.go(-1);
           } 
        }).catch(error => {
        if (!error.response) {
            this.propsmodalalert=true;
        }
      })
 },
 /*intervall: function () {
            setInterval(() => {
               this.getmessages();
            }, 2000);
        },*/
 sendmessage(){
       if(this.text_send_message.length > 0){
          this.send_text=this.text_send_message;
          this.blockformtextarea=false;
          this.text_send_message="";
           clearTimeout(this.timer);
             this.timer1 = setTimeout ( () => {
                this.blockformtextarea=true;
             }, this.pauza1);
          const newPost ={
                   id:Date.now(),
                   dt: new Date().toLocaleTimeString(),
                   text_message:this.send_text,
                   sender:true
            };
             clearTimeout(this.timer);
             this.timer = setTimeout ( () => {
               window.scrollTo(0, document.body.scrollHeight+100 || document.documentElement.scrollHeight+100);
             }, this.pauza);
           // window.scrollTo(0,0);
          
            if(this.messages)  this.messages.push(newPost);
         else  this.messages=newPost;
         axios({
           method: 'post',
            url: 'https://agros.tj/api/sendmessage',
            data: {
            idobv:this.id_obv,
            token: localStorage.getItem("token"),
            teluser2:this.teluser2,
            text:this.send_text,
           }
          })
        .then(res=>{
          if(!res.data.msg){
            if(res.data.status){
               console.log("send");

            }
           
          }
           else{
            // localStorage.removeItem("token");
             this.$router.go(-1);
           } 
        }).catch(error => {
        if (!error.response) {
            this.propsmodalalert=true;
        }
      })
    }
 }    
},
 computed: {
   kodmessage(){
    return this.$store.getters.getkodmessage;
   },
   teluser2message(){
    return this.$store.getters.getteluser2message;
   },
},
mounted(){
   if(!this.kodmessage){
       this.$router.go(-1); 
    }
    else{
        this.id_obv=this.kodmessage;
        this.$store.commit("addkodmessage","");
        this.teluser2=this.teluser2message;
         this.$store.commit("addteluser2message","");
    }
   this.getmessages();
   this.$metrika.hit("chat");
}
}
</script>
<style scoped>
.cont{
background-color: #f5f5f5; 
}
.contend_chat{
background-color: #f5f5f5;
width:100%;
max-width: 600px;
}
.content_sendmessage{
min-height:60px;
left: 0px;
bottom:0px;
position: fixed;
min-width:100%;
max-width:100%;
background-color:#ffffff;   
}
.textareat{
    width:100%;
    border: 1px solid #fff;
}
.content_sendmessage img{
width:35px;
padding:5px;
}
.content_sendmessage .divsend{
margin-top:3px;
max-width:600px;
}
.content_head {
height:55px;
left: 0px;
top:0px;
position: fixed;
min-width:100%;
max-width:100%;
background-color:#0b7367;
box-shadow: 0 0 4px 0 rgba(0,0,0,1);
}
.title-h {
font-size: 20px;
text-decoration: none;
}
.imgback {
color:#ffffff;
margin-left:5px;
margin-right: 10px;
margin-top:5px;
}
.title_overflow{
word-wrap: break-word;
font-size:18px;
margin-top:2px;
color:#ffffff;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
}
.title_obv{
word-wrap: break-word;
text-decoration:none;
color:#ccc;
font-size:18px;
margin-top:2px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
}
.user{
text-align:right;
}
.user label{
  font-size:14px;
  color: #333;
  margin-right: 7px ;
}
.user  div{
font-size:18px;
display: inline-block;
margin:7px;
max-width: 70%;
background-color:#99ccff;
padding:10px 5px 10px 5px;
border-radius:10px;
word-wrap:break-word;
word-break: break-word;
   -webkit-hyphens: auto;
   -moz-hyphens: auto;
   -ms-hyphens: auto;
   hyphens: auto;	
}
.nouser{
text-align:left;
}
.nouser label{
  font-size:14px;
  color: #333;
  margin-left: 7px ;
}
.nouser  div{
font-size:18px;
max-width: 70%;
display: inline-block;
margin:7px;
background-color:#ffffff;
padding:10px 5px 10px 5px;
border-radius:10px;
word-wrap:break-word;
word-break: break-word;
   -webkit-hyphens: auto;
   -moz-hyphens: auto;
   -ms-hyphens: auto;
   hyphens: auto;	
}
</style>
