<template>
<div>
<div align="center"  class="ddd">
     <div class="content_images">
       <hooper :initialSlide="init" :centerMode="true"  >
        <slide v-for="img in getpostimgobv " :key="img.id" >
           <img :src = img.image class="images" @click="f_mashtab(img.image)" />
        </slide> 
        <hooper-pagination slot="hooper-addons"></hooper-pagination>
      </hooper>
       </div>
       <div class="close" onclick="javascript:history.back(); return false;">
         <svg  width="1.5em" height="1.5em" viewBox="0 0 24 24">
         <path d="M18.36 19.78L12 13.41l-6.36 6.37l-1.42-1.42L10.59 12L4.22 5.64l1.42-1.42L12 10.59l6.36-6.36l1.41 1.41L13.41 12l6.36 6.36z" fill="currentColor"></path></svg>  
       </div>
</div>
<transition name="fade">
<div class="content_zoom" v-if="zoom">
 <div class="wrapper_zoom">
   <div>
     
      <img :src = imgzoom  class="imgzoom" @click="f_mashtabclose()" >
    
   </div>
 </div>
  
</div>
 </transition>
</div>
</template>
<script>
import 'hooper/dist/hooper.css';
import {
  Hooper,
  Slide,
  Pagination as HooperPagination
  } from 'hooper';
export default{
components:{  Hooper,
    Slide, HooperPagination, 
  },
  data (){
    return {
        propsmodalalert:false,
        loader:false,
        loadaxios:true,
        init:this.$route.params.id-1,
        imgzoom:"",
        zoom:false,
        touchtime:0,
        
    }
  },
  computed: {
   getpostimgobv(){
    return this.$store.getters.getpostimgobv; 
   },
},
methods:{
f_mashtab(img){
if (this.touchtime == 0) {
this.touchtime = new Date().getTime();
} else {
if (((new Date().getTime()) - this.touchtime) < 700) {
this.touchtime = 0;
this.zoom=true;
this.imgzoom=img;
} else {
this.touchtime = new Date().getTime();
}
}
},
f_mashtabclose(){
  if (this.touchtime == 0) {
this.touchtime = new Date().getTime();
} else {
if (((new Date().getTime()) - this.touchtime) < 700) {
this.touchtime = 0;
this.zoom=false;
 this.imgzoom="";
} else {
this.touchtime = new Date().getTime();
}
}
}
},
mounted(){
  if(!this.getpostimgobv.length) {
      this.$router.go(-1);
  }
   
}  
}
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}
.content_zoom{
   position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #111111;
  display: table;
  z-index: 38;
  margin: 0 auto;
}
.wrapper_zoom{
height:100%;
width:100%;
overflow-x: auto;
overflow-y: auto;
white-space: nowrap;
z-index: 37;
position: fixed;
}
.wrapper_zoom::-webkit-scrollbar {
    display: none;
}
.wrapper_zoom div{
display: inline-block;
vertical-align: middle;
position: relative;
transition: 0.5s all ease-in-out;
}
.wrapper_zoom:after {
  content: "";
  width:0%;
  height: 100%; 
  display: inline-block;
  vertical-align: middle;
}
.wrapper_zoom::-webkit-scrollbar {
    display: none;
}
.imgzoom{
width:800px;
margin:10px;
}
.images{
width: 100%;
position: relative;
top: 50%;
transform: translateY(-50%);
transition: 0.5s all ease-in-out;
 
}
.content_images {
    width:100%;
    max-width: 600px;
    height: 100%;
    position:relative;
/*overflow-x: auto;
overflow-y: auto;*/
vertical-align:Middle;
}
.ddd{
    background-color: #111111;
    display: block;
     height: 100vh;
     margin-left:-6px;
     margin-right: -6px;
}
.close{
right: 10px;
top:10px;
width:25px;
height: 25px;
position: fixed;
color:#fff;
background-color: #111111;
padding:5px;
border-radius: 50%;
}
</style>

