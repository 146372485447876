<template >
<div align="center">
    <div class="content_dialog" v-for="dial in dialog" :key="dial.id" @click="f_message(dial.kod_obv, dial.tel_user2)">
         <table>
              <tr>
                   <td>
                    <div class="div_img">
                      <img :src="dial.photo">
                    </div>
                   </td>
                   <td width="100%">
                    <table>
                         <tr>
                              <td width="100%" valign="top"><div class="title over"><span>{{dial.title}}</span></div></td>
                              <td><label class="dt">{{dial.last_dt}}</label></td>
                         </tr>
                         <tr>
                              <td width="100%"><div class="name over"><span>{{dial.name_user2}}</span></div></td>
                              <td align="right"><div v-if="dial.sender && dial.unread > 0" class="unread">{{dial.unread}}</div></td>
                         </tr>
                         <tr>
                              <td width="100%" colspan="2"><div class="message over"><span>{{dial.last_message}}</span></div></td>
                         </tr>
                    </table>
                   </td>
              </tr>
         </table>
    </div>
</div>
</template>
<script>
export default {
     props: {
      dialog: Array,
     },
     methods:{
          f_message(kod, teluser2){
               this.$store.commit("addkodmessage",kod);
               this.$store.commit("addteluser2message",teluser2);
               this.$router.push({name:"chat"}); 
          }
     }
}
</script>
<style scoped>
.content_dialog{
background-color: #fff;
border-radius: 10px;
margin:10px 0px 10px 0px;
width:100%;
max-width: 600px;
}
.content_dialog img{
width:100px;
border-radius: 10px;
margin-bottom:-4px;
margin-right: 3px;
}
.content_dialog .div_img {
width:100px;
height: 95px;
border-radius: 10px;
background-color: #f5f5f5;
}
.content_dialog table{
border-collapse: collapse;
}
.title{
font-size:18px;
Color:#000;  
}
.name{
font-size:18px;
Color:#000;     
}
.message{
font-size:18px;
Color:#777; 
}
.unread{
padding:1px 5px;
background-color: #ff6600;
display: inline-block;
color:#fff;
font-size:14px;
border-radius: 10px;

}
.dt{
font-size:14px;
Color:#777; 
}
.over{
 word-wrap: break-word;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
-ms-word-break: break-all;
 word-break: break-all;     
}
</style>