<template>
    <div>
     
      <paddingheader />
         <div class="margin_div"></div>
          <div class="content_kart">
          <div v-for="getpodkateg in getpodkategobvs " :key="getpodkateg.id">
          <Karta  :obvs="getpodkateg" /> 
          </div>
          </div>
         
        <div ref="observer" class="observer"></div>

      <div class="spin" align="center">
       <div  v-if="loader" >
         <br/> <br/>   
         <Spinner />
         <br/> <br/>   
        </div>
         <div v-else>
           <button v-if="pei" class="btns" @click="getobvs();">ПОКАЗАТЬ ЕЩЕ</button>
        </div>
        </div>
       <noconnection :propsmodalalert="propsmodalalert" @emitnoconnection="emitnoconnection" />
    <paddingheader />
    <header2 :titlearr="getpostpodkateg" />  
    <Footermenu />
    </div>
</template>
<script>
import axios from 'axios';
import noconnection from '../blocks/noconnection'
import header2 from '../blocks/header2';
import paddingheader from '../blocks/paddingheader';
import Footermenu from "../blocks/footermenu";
import Spinner from '../blocks/spinner';
import Karta from '../blocks/karta';
export default{
components:{
    Spinner, header2, paddingheader, Footermenu, noconnection, Karta

  },
data() {
    return {
        loader:false,
        propsmodalalert:false,
        loadaxios:true,  
        pei:true,
         
    }
  },
created(){
    
     
},
methods:{
emitnoconnection(){
   this.propsmodalalert=false;
   if(!this.getpostsilka.length){
     this.getsilka();
   }
   else {
     this.loadaxios=true; 
     this.getobvs();
   }
   //this.getsilkapodkateg();
   
 },
 getsilka(){
    this.loader=true;
    try{
         axios({
           method: 'post',
            url: 'https://agros.tj/api/getpostpodkateg',
            data: {
            pk:this.$route.params.id,
            gorod: localStorage.getItem("gorod"),
           }
          })
        .then(res=>{
          
            if(res.data.status){
               this.$store.commit("addpostpodkateg",res.data.podkateg)
               this.loader=false;
            }
            else{
             alert("error");
            }
           
        }).catch(error => {
        if (!error.response) {
            this.propsmodalalert=true;
        }
      })
     } 
     catch (e){alert ("Error");}
 },
 getobvs(){
  if(this.loadaxios){
   this.loader=true;
    this.loadaxios=false; 
         axios({
           method: 'post',
            url: 'https://agros.tj/api/getpodkategobvs',
            data: {
            pk:this.$route.params.id,
            limit:this.getpodkategobvs.length,
            gorod: localStorage.getItem("gorod"),
           }
          })
        .then(res=>{
          
            if(res.data.status){
               this.$store.commit("addpostpodkategobvs",res.data.obvs);  
               this.loader=false; 
               this.loadaxios=true; 
            }
            else{
             this.loader=false; 
             this.pei=false;
            }
           
        }).catch(error => {
        if (!error.response) {
            this.propsmodalalert=true;
        }
      })
 }
 },
 clearvuex(){
     this.$store.commit("addpostobv",new Array);
     this.$store.commit("addpostimgobv",new Array);
     this.$store.commit("addpostfiltirobvs",new Array); 
    },
},
 computed: {
   getpostpodkateg(){
    return this.$store.getters.getpostpodkateg; 
   },
    getpodkategobvs(){
    return this.$store.getters.getpostpodkategobvs;
   } 
},
mounted(){
  this.clearvuex();
  if(!this.getpostpodkateg.length && this.getpostpodkateg.key!=this.$route.params.id) {
     this.$store.commit("addpostpodkateg",new Array);
     this.$store.commit("addpostpodkategobvs",new Array);
     this.getsilka();
     this.getobvs();
  }
  const options = {
    rootMargin: '700px',
    threshold: 1.0
     }
     const callback = (entries)=> {
     if(entries[0].isIntersecting){
        this.getobvs();
        this.pei=false;
      }
     };
      const observer = new IntersectionObserver(callback, options);
     observer.observe(this.$refs.observer);
     this.$metrika.hit(this.$route.params.id);
    },


}
</script>
<style scoped>
</style>

