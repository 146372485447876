import Vue from 'vue'
import App from './App.vue'
import ImageUploader from 'vue-image-upload-resize'
Vue.use(ImageUploader);
import VueRouter from 'vue-router'
import VueYandexMetrika from 'vue-yandex-metrika'                               
Vue.use(VueRouter)
import router from './router/index'
Vue.config.productionTip = false
import store from './store'
import VueClazyLoad from 'vue-clazy-load'
Vue.use(VueClazyLoad)
Vue.use(VueYandexMetrika, {
    id: 69633715,
    //router: router, //this.$metrika.hit(path
    env: process.env.NODE_ENV
});
new Vue({
 router, store,
  render: h => h(App), 
}).$mount('#app')
