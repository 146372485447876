export default{
    actions: {
      
    },
    mutations: {
    addpostfiltirobvs(state, newpost){
        if(newpost.length) state.postobvs.push(newpost);
        else state.postobvs=newpost;
        
    },
    },
    state: {
        postobvs: [],
    },
    getters:{
        getpostfiltirobvs(state){
            return state.postobvs
        },  
    }
}