import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import kateg from './modules/kateg'
import podkateg from './modules/podkateg'
import home from './modules/home'
import obv from './modules/obv'
import favorites from './modules/favorites'
import search from './modules/search'
import dialogs from './modules/dialogs'
Vue.use(Vuex)
export default new Vuex.Store({
    modules:{
     user, kateg, home, podkateg, obv, favorites, search, dialogs
    }
})