import { render, staticRenderFns } from "./bezopasnost.vue?vue&type=template&id=5aa46120&scoped=true&"
import script from "./bezopasnost.vue?vue&type=script&lang=js&"
export * from "./bezopasnost.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aa46120",
  null
  
)

export default component.exports