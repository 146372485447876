<template>
<div>
<div class="contentgorod" >
<div class="placeholder_gorod">{{txtplaceholder_gorod}}</div>

<div v-if="!iconkadel" @click="showModal = true;" >
<table>
<tr>
<td width="100%">
<div  class='divgorod'>{{allg}}</div>
</td>
<td  align="center">
<div >
<svg width="0.8em" height="0.8em" viewBox="0 0 16 16"><g fill="currentColor"><path fillRule="evenodd" clipRule="evenodd" d="M7.976 10.072l4.357-4.357l.62.618L8.284 11h-.618L3 6.333l.619-.618l4.357 4.357z"></path></g></svg>
</div>
</td>
</tr>
</table>
</div>

<div v-else>
<table>
<tr>
<td width="100%">
<div  class='divgorodactiv' @click="showModal = true" >{{txtgorod}}</div>
</td>
<td  align="center">
<div @click="clear()">
  <svg  width="0.8em" height="0.8em" viewBox="0 0 24 24"><path d="M18.36 19.78L12 13.41l-6.36 6.37l-1.42-1.42L10.59 12L4.22 5.64l1.42-1.42L12 10.59l6.36-6.36l1.41 1.41L13.41 12l6.36 6.36z" fill="currentColor"></path></svg>
</div>
</td>
</tr>
</table>
</div>
</div>

<div class="success_gorod">{{txtsuccess_gorod}}</div>
<modal v-if="showModal" @close="showModal = false;">
<span slot="header">Город или регион</span>
<div slot="body">
 <div v-for="g in arrgorod" :key="g.id" class="block_radio">
 <input class="custom-radio" name="color" v-model="gorod" type="radio"
 :id="'g'+g.id" :value="g.id" @change="selectgorod">
<label  :for="'g'+g.id">{{g.text}}</label>
 </div>
</div>
</modal>

</div>
</template>
<script>
import modal from "../blocks/modal";
export default {
   components:{
      modal, 
   },
   props: {   
    goro: String,
    propsgorodsuccess: String,
    allgrord:String,
   }, 
  data() {
    return {
      allg:"Город или регион",
      gorod: this.goro,
      txtgorod:"",
      iconkadel:false,
      txtplaceholder_gorod:"",
      txtsuccess_gorod:"",
      showModal: false,
      arrgorod:[
      {id:"12", text:"Душанбе"},{id:"36", text:"Худжанд"},
      {id:"15", text:"Абдурахмони Джоми"},{id:"200", text:"Айни"},
      {id:"17", text:"Ашт"},{id:"22", text:"Бальджуван"},
      {id:"26", text:"Бободжон Гаффуров"},{id:"90", text:"Бохтар (Курган-Тюбе)"},
      {id:"14", text:"Бустон (Чкаловск)"},{id:"39", text:"Вандж"},
      {id:"203", text:"Варзоб"},{id:"57", text:"Вахдат"},
      {id:"41", text:"Вахш"},{id:"87", text:"Восе"},
      {id:"78", text:"Гиссар"},{id:"400", text:"Горная Матча"},
      {id:"336", text:"Гулистон (Кайраккум)"},{id:"910", text:"Дангара"},
      {id:"59", text:"Дарваз"},{id:"98", text:"Деваштич (Ганчи)"},
      {id:"32", text:"Джаббор Расулов"},{id:"198", text:"Джайхун (Кумсангир)"},
      {id:"139", text:"Джалолиддина Балхи (Руми)"},{id:"114", text:"Джами"},
      {id:"109", text:"Дусти (Джиликуль)"},{id:"405", text:"Зафарабад"},
      {id:"458", text:"Истаравшан"},{id:"9", text:"Истиклол"},
      {id:"38", text:"Исфара"},{id:"69", text:"Ишкашим"},
      {id:"61", text:"Кабодиён"},{id:"332", text:"Канибадам"},
      {id:"16", text:"Куляб"},{id:"215", text:"Кушониён (Бохтар)"},
      {id:"112", text:"Лахш (Джиргиталь)"},{id:"67", text:"Леваканд (Сарбанд)"},
      {id:"28", text:"Матча"},{id:"71", text:"Муминабад"},
      {id:"74", text:"Мургаб"},{id:"468", text:"Носири Хусрав"},
      {id:"165", text:"Нурабад"},{id:"418", text:"Нурек"},
      {id:"315", text:"Пенджикент"},{id:"369", text:"Пяндж"},
      {id:"254", text:"Рашт"},{id:"319", text:"Рогун"},
      {id:"263", text:"Рошткала"},{id:"49", text:"Рудаки"},
      {id:"73", text:"Рушан"},{id:"62", text:"Сангвор (Тавильдара)"},
      {id:"93", text:"Спитамен"},{id:"48", text:"Таджикабад"},
      {id:"329", text:"Темурмалик"},{id:"905", text:"Турсунзаде"},
      {id:"506", text:"Файзабад"},{id:"700", text:"Фархор"},
      {id:"807", text:"Хамадани"},{id:"318", text:"Ховалинг"},
      {id:"719", text:"Хорог"},{id:"331", text:"Хуросон"},
      {id:"187", text:"Шамсиддин Шохин (Шуроабад)"},{id:"191", text:"Шахринав"},
      {id:"925", text:"Шахристон"},{id:"963", text:"Шахритус"},
      {id:"955", text:"Шугнан"},{id:"603", text:"Яван"},
      ],
       tgorod:"" , 
    };
  },
  methods: {
   selectgorod() {
     if(this.gorod && this.tgorod != this.gorod){
       const values = Object.values(this.arrgorod);
      for (let i = 0; i < values.length; i += 1) {
         if (values[i].id === this.gorod) this.txtgorod=values[i].text;
      }
      this.$emit('emitgorod', this.gorod);
      this.$emit('emittextgorod', this.txtgorod);
      this.showModal=false;
      this.txtplaceholder_gorod="Город или регион";
      this.iconkadel=true;
      this.txtsuccess_gorod="";
      this.tgorod=this.gorod;
     } 
  },
  clear(){
      this.gorod="";
      this.$emit('emitgorod', this.gorod);
      this.$emit('emittextgorod', this.txtgorod="");
      this.txtplaceholder_gorod="";
      this.iconkadel=false;
      this.txtgorod="";
      this.tgorod="";
  }
},
    watch: {
    goro: {
      handler() {
        this.gorod=this.goro;
         this.selectgorod();   
      }
    },
    allgrord: {
      handler() {
         this.allg="Все города";
      }
    },
    propsgorodsuccess: {
      handler() {
        this.txtsuccess_gorod=this.propsgorodsuccess;
      }
    },
 } 
     
  };
</script>
<style scoped>
.placeholder_gorod {
    height: 16px;
    font-size: 14px;
    color:#666666;
    margin-left:3px;
}
.block_radio{
  margin-bottom: 1.4em;
}
.divgorodactiv{
  width:100%;
  color:#000000;
  overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
}
.divgorod{
  width:100%;
  color:#777777;
}
.success_gorod{
    height: 15px;
    font-size: 15px;
    color:#c20404;
    margin-left:3px;
}
.contentgorod{
font-size: 20px;
    border-bottom: 1px solid #000000;
    padding:5px 5px 5px 0px;
}
 label {
     font-size: 20px;
     width:100%;
 }
  

</style>