<template>
    <div class="margin_12">
     <header3 :title="title"/>
     <paddingheader />
     <br/>
     <div v-html="text"></div>
       <div v-if="loading" align="center">
           <div class="spin">
           <Spinner />
           </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import header3 from '../blocks/header3';
import paddingheader from '../blocks/paddingheader';
import Spinner from '../blocks/spinner';
export default{
components:{
    Spinner, header3, paddingheader

  },
data() {
    return {
        title:"Безопасность - Agros.tj",
        loading:false,
        text:"",
        
    }
  },
methods:{
 bezopasnost(){
     this.loading=true;
         axios({
           method: 'post',
            url: 'https://agros.tj/api/bezopasnost',
            data: {
            ddd: 1,
           }
          })
        .then(res=>{
            if(res.data.status){
              this.loading=false;
              this.text=res.data.text
            }
        });
   },
},
mounted(){
  this.bezopasnost();
}
}
</script>
<style scoped>



</style>
