export default{
    actions: {
      
    },
    mutations: {
    addpostuser(state, newpost){
        state.postuser=newpost;
    },
    addpostuserobvs(state, newpost){
        state.postuserobvs=newpost;
    }
    },
    state: {
        postuser: [],
        postuserobvs: [],
    },
    getters:{
        getpostuser(state){
            return state.postuser
        },
        getpostuserobvs(state){
            return state.postuserobvs
        },  
    }
}