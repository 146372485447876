export default{
    actions: {
      
    },
    mutations: {
    addposthomeobvs(state, newpost){
        if(newpost.length) state.postobvs.push(newpost);
        else state.postobvs=newpost;
    },
    addkeyworld(state, newkeyworld){
        state.keyworl=newkeyworld;
    },
    },
    state: {
        postobvs: [],
        keyworl:"",
    },
    getters:{
        
        getposthomeobvs(state){
            return state.postobvs
        },
        getkeyworld(state){
            return state.keyworl;
        }, 
    }
}