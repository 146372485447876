<template>
<div class="menu">
<table>
<tr><td>
<router-link to="/"><div><div><img src="../assets/image/home.png" ></div><label>Agros.tj</label></div></router-link>
</td><td>
<router-link to="/favorites"><div><div><img src="../assets/image/serdichka.png" ></div><label>Избранное</label></div></router-link>
</td><td>
<router-link to="/podat" class="podat_footer"><div><div><img src="../assets/image/podat_obv.png" ></div><label>Разместить</label></div></router-link>
</td><td >
<router-link to="/message"><div><div><img src="../assets/image/message.png" >
</div><label>Сообщения</label></div><div v-if="getunread>0" class="i_unread">{{getunread}}</div></router-link>
</td><td>
<router-link to="/cbn"><div><div><img src="../assets/image/user.png" ></div><label>Профиль</label></div></router-link>
</td></tr></table>
</div>
</template>
<script>
export default{
 
computed:{
getunread(){
    return this.$store.getters.getunread;
   },
}
}
</script>
<style scoped>
.menu{
border-top:1px solid #cccccc;
background-color:#ffffff;
display:block-inline;
bottom:0px;
left: 0px;
position: fixed;
min-width:100%;
max-width:100%;
z-index: 1000;
}
.i_unread{
  margin-top:-45px;
  display: inline-block;
  width:13px;
  height: 13px;
  background-color: #ff6600;
  border-radius: 50%;
  padding:2px;
  font-size: 10px;
  color:#fff;
position:absolute; 
}
.menu a{
text-decoration:none;
color:#000000;
padding:0px;
}
.menu img{
width:28px;
margin-bottom: -6px;
background-color: #888888;
}
.menu table{
width: 100%;
padding:0px;
}
.menu table td{
width: 20%;
text-align: center;
}
.menu label{
font-size:10px;
}
.menu a.router-link-exact-active {
  color: #0b7367;
}
.menu a.router-link-exact-active img{
  background-color: #0b7367;
}
.podat_footer img{
background-color: #309533;
width:35px;
margin-top:-6px;
border-radius: 50%;
}
</style>