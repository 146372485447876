<template>
    <div>       
    <div class="content_obv" v-if="!loader">
       <div class="content_images" >
       <hooper>
        <slide v-for="img in getpostimgobv " :key="img.id" >
          <router-link  :to="{name:'img', params:{id:img.id}}">
           <img :src = img.image class="images" />
          </router-link>
        </slide> 
        <hooper-pagination slot="hooper-addons"></hooper-pagination>
      </hooper>
       </div>
       
       <div class='title'>
           <h1>{{getpostobv.title}}</h1>
           <span> {{ getpostobv.sena }}  {{getpostobv.torg}} {{getpostobv.obmen}}</span>  
       </div>
       <div class="content_s_g_d">
         <table>
           <tr>
             <td><img class="image_s_g_d" src="../assets/image/geo_mobil.png" ></td>
             <td width="100%" align="left"><span> {{getpostobv.gorod}}</span></td>
           </tr>
         </table>
       </div>
       <div class="content_s_g_d">
         <table>
           <tr>
             <td>
              <div class="content_o_p_i_div"><b>Описание</b><br/><br/><label>{{getpostobv.body}}</label></div> 
             </td>
           </tr>
         </table>
       </div>
       <div class="content_o_p_i">
         <div class="md">
         <table>
           <tr>
             <td><div class="divspan"><span>ID</span></div></td><td><div class="divlabel"><label> {{getpostobv.id}}</label></div></td>
           </tr><tr>
             <td><div class="divspan"><span>Размещено</span></div></td><td><div  class="divlabel"><label> {{getpostobv.dt}}</label></div></td>
           </tr><tr>
             <td><div class="divspan"><span>Просмотры</span></div></td><td><div  class="divlabel"><label>{{getpostobv.prasmotr}}</label></div></td>
             </tr><tr>
             <td valign="top"><div class="divspan"><span>Категория</span></div></td><td><div  class="divlabel"><label>{{getpostobv.kateg}}</label></div></td>
             </tr><tr>
             <td valign="top"><div class="divspan"><span>Подкатегория</span></div></td><td><div  class="divlabel"><label> {{getpostobv.podkateg}}</label></div></td>
           </tr>
         </table>

       </div>
       </div>
       <div class="content_user">
         <div class="zhalob">
           <span @click="zhalob_display=!zhalob_display"><b>ПОЖАЛОВАТЬСЯ</b></span>
           <div v-if="zhalob_display">
             <br/>
             <label>Пожалуйства, опишите проблему</label><br/>
             <resizable-textarea ref="resize">
             <textarea ref="textarea" rows="1" class="textareat" v-model.trim="text_zhalob" ></textarea>
             </resizable-textarea>
              <div class="success">{{text_success_zhalob}}</div>
              <div align="right">
                <button class="btns" @click="f_zhalob(getpostobv.id)">ОТПРАВИТЬ</button>
                </div> 
           </div>
           </div>
           <div class="user">
                <div class="user_img">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--fa-solid" width="80" height="80" preserveAspectRatio="xMidYMid meet" viewBox="0 0 496 512">
                  <path d="M248 8C111 8 0 119 0 256s111 248 248 248s248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88s-88-39.4-88-88s39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2c18.8-35.4 55.6-59.8 98.5-59.8c2.4 0 4.8.4 7.1 1.1c13 4.2 26.6 6.9 40.9 6.9c14.3 0 28-2.7 40.9-6.9c2.3-.7 4.7-1.1 7.1-1.1c42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z" fill="currentColor"></path></svg>
                </div>
                <span>{{getpostobv.name_user}}</span>
           </div>
       </div>
       
       <div>
       <div class="content_tel_mesg" align="center" v-if="!getpostobv.chat">
        <table>
          <tr>
            <td>
              <div class="tel" @click="f_clicktel(getpostobv.id)">
              <a :href= getpostobv.telsilka>
              <table>
                <tr>
                  <td> <img  src="../assets/image/ic_emergency.png" > </td>
                  <td><span>{{getpostobv.tel}}</span></td>
                </tr>
              </table>
              </a>
              </div>
            </td>
            <td align="right">
              <div class="message"  @click="f_message(getpostobv.id, getpostobv.telmesssage)">
              <table>
                <tr>
                  <td><div class="img_msg"> <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--ion" width="28" height="28" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512">
                  <path d="M144 464a16 16 0 0 1-16-16v-64h-24a72.08 72.08 0 0 1-72-72V120a72.08 72.08 0 0 1 72-72h304a72.08 72.08 0 0 1 72 72v192a72.08 72.08 0 0 1-72 72H245.74l-91.49 76.29A16.05 16.05 0 0 1 144 464z" fill="currentColor"></path></svg></div> </td>
                  <td><span>Написать</span></td>
                </tr>
              </table>
              </div>
            </td>
          </tr>
        </table>
       </div>
         <div v-else class="content_tel_mesg" align="center">
         <div class="tel" @click="f_clicktel(getpostobv.id)">
              <a :href = getpostobv.telsilka>
              <table>
                <tr>
                  <td> <img  src="../assets/image/ic_emergency.png" > </td>
                  <td><span>{{getpostobv.tel}}</span></td>
                </tr>
              </table>
              </a>
          </div>
       </div>
       </div>
       </div>
         
       <div class="spin">
        <div  align="center" v-if="loader" >
         <br/><br/><br/><br/><Spinner /> 
        </div>
        </div>

       <div class="content_head" align="center">
       <table >
         <tr>
         <td align="left">
           <div onclick="javascript:history.back(); return false;" class="imgback">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--ep" width="30" height="30" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024">
            <path fill="currentColor" d="M685.248 104.704a64 64 0 0 1 0 90.496L368.448 512l316.8 316.8a64 64 0 0 1-90.496 90.496L232.704 557.248a64 64 0 0 1 0-90.496l362.048-362.048a64 64 0 0 1 90.496 0z"></path></svg></div>
         </td>
          <td align="right"  width="100%"></td>
          <td align="right"  >
           <div @click="f_sedichka(getpostobv.id)"  class="serdichka" :class="{serdichkaactiv: serdichka}">
               <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--jam" width="30" height="30" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
               <path d="M11.293 5.55l.707.708l.707-.707a5 5 0 1 1 7.071 7.071l-7.07 7.071a1 1 0 0 1-1.415 0l-7.071-7.071a5 5 0 1 1 7.07-7.071z" fill="currentColor"></path></svg>
            </div>
          </td>
          <td>
            <div class="serdichka" @click="shape()">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--mdi" width="28" height="28" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
            <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7c0-.24-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81a3 3 0 0 0 3-3a3 3 0 0 0-3-3a3 3 0 0 0-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.15c-.05.21-.08.43-.08.66c0 1.61 1.31 2.91 2.92 2.91c1.61 0 2.92-1.3 2.92-2.91A2.92 2.92 0 0 0 18 16.08z" fill="currentColor"></path></svg>
            </div>
            </td>	   
         </tr>
        </table>
       </div>
       <noconnection :propsmodalalert="propsmodalalert" @emitnoconnection="emitnoconnection" />
    </div>
</template>
<script>
import axios from 'axios';
import noconnection from '../blocks/noconnection'
import Spinner from '../blocks/spinner';
import 'hooper/dist/hooper.css';
import ResizableTextarea from '../blocks/ResizableTextarea'
import {
  Hooper,
  Slide,
  Pagination as HooperPagination
  } from 'hooper';
  
export default{
components:{
    Spinner,   noconnection,   Hooper,
    Slide, HooperPagination, ResizableTextarea, 
  },
data() {
    return {
        loader:true,
        zhalob_display:false,
        text_success_zhalob:"",
        text_zhalob:"",
        propsmodalalert:false,
        loadaxios:true,
        serdichka:false,
        images: [],
        post:[{
          id:'',
          title:"",
          sena:'',
          torg:'',
          obmen:'',
          gorod:"",
          kateg:"",
          podkateg:"",
          dt:"",
          name_user:"",
          tel:"",
          telsilka:"",
          body:"",
          prasmotr:"",
          chat:"",
          serdichka:"",
          telmesssage:"",
        } 
        ],

         
     }
    
  },
created(){
  if(localStorage.getItem("token")){
      axios.defaults.headers.common["Authorization"] = "Bearer "+localStorage.getItem("token"); 
  }     
},
methods:{
emitnoconnection(){
   this.propsmodalalert=false;
     this.getobvs();
 },
 f_sedichka(kodsedichka){
   if(localStorage.getItem("token") && kodsedichka){
       this.$store.commit("addsedichkaobv");
       this.serdichka=this.getpostobv.serdichka;
    axios({
           method: 'post',
            url: 'https://agros.tj/api/addserdichka',
            data: {
            kod:kodsedichka,
            token:localStorage.getItem("token"),
           }
          })
        .then(res=>{
            if(res.data.status){
               console.log("true");
            }
        });
   }
   else{
    this.$router.push({name:"login"}); 
   }
 },
 shape(){
if (navigator.share) {
  navigator.share({
    title: 'Откройте для себя Agros.tj',
    text: 'Agros.tj - '+ this.getpostobv.title,
    url: 'https://agros.tj/obv/'+this.$route.params.id,
  })
    .then(() => console.log('Удалось поделиться'))
    .catch((error) => console.log('Не удалось поделиться', error));
}
 },
 f_zhalob(kod){
      if(this.text_zhalob.length<2){
        this.text_success_zhalob="Опишите подробно!";
      }
      else{ 
        this.text_success_zhalob="";
        this.zhalob_display=false;
    axios({
           method: 'post',
            url: 'https://agros.tj/api/addzhalob',
            data: {
            kod:kod,
            text:this.text_zhalob,
           }
          })
        .then(res=>{
            if(res.data.status){
               console.log("true");
               this.text_zhalob="";
            }
        });
      }
 },
 f_clicktel(kod){
    axios({
           method: 'post',
            url: 'https://agros.tj/api/addclick',
            data: {
            kod:kod,
           }
          })
        .then(res=>{
            if(res.data.status){
               console.log("true");
            }
        });
 },
 f_message(kodmessage, teluser2){
if(localStorage.getItem("token") && kodmessage){
      this.$store.commit("addkodmessage",kodmessage);
      this.$store.commit("addteluser2message",teluser2);
      this.$router.push({name:"chat"}); 
   }
   else{
    this.$router.push({name:"login"}); 
   }
 },
 getobvs(){
         axios({
           method: 'post',
            url: 'https://agros.tj/api/getoneobv',
            data: {
            id:this.$route.params.id,
            token:localStorage.getItem("token"),
            gorod: localStorage.getItem("gorod"),
           }
          })
        .then(res=>{
          this.loader=false;
            if(res.data.status){
              this.post.id=res.data.obv.id;
              this.post.title=res.data.obv.title;
              
              if(res.data.obv.sena==0.0001){
               this.post.sena="Цена не указана";
              }
              else{ this.post.sena=res.data.obv.sena.toLocaleString('ru-RU') + " сомони" 
               if(res.data.obv.torg==1){
                   this.post.torg=". торг";
                }
                if(res.data.obv.obmen==1){
                   this.post.obmen=". обмен";
                }
              }
              this.post.gorod=res.data.obv.adress_text;
              this.post.kateg=res.data.obv.kateg;
              this.post.podkateg=res.data.obv.podkateg;
              this.post.dt=res.data.obv.dt;
              this.post.name_user=res.data.obv.name;
              this.post.tel="+992"+res.data.obv.tel;
              this.post.telmesssage=res.data.obv.tel;
              this.post.telsilka="tel:+992"+res.data.obv.tel;
              this.post.body=res.data.obv.body;
              this.post.prasmotr=res.data.obv.prasmotr;
              if(res.data.obv.chat==1)  this.post.chat=true;
              else this.post.chat=false; 
              this.post.serdichka=res.data.obv.serdichka;
              this.serdichka=res.data.obv.serdichka;
                this.$store.commit("addpostobv",this.post);

              if(res.data.obv.img_1){
               const newPost ={
                   id: 1,
                   image:res.data.obv.img_1
               };
               this.images.push(newPost); 
               }
               if(res.data.obv.img_2){
               const newPost ={
                   id: 2,
                   image:res.data.obv.img_2
               };
               this.images.push(newPost); 
               }
               if(res.data.obv.img_3){
               const newPost ={
                   id: 3,
                   image:res.data.obv.img_3
               };
               this.images.push(newPost); 
               }
               if(res.data.obv.img_4){
               const newPost ={
                   id: 4,
                   image:res.data.obv.img_4
               };
               this.images.push(newPost); 
               }
               if(res.data.obv.img_5){
               const newPost ={
                   id: 5,
                   image:res.data.obv.img_5
               };
               this.images.push(newPost); 
               }
               if(res.data.obv.img_6){
               const newPost ={
                   id: 6,
                   image:res.data.obv.img_6
               };
               this.images.push(newPost); 

               }
                this.$store.commit("addpostimgobv",this.images);
            }
            else{
             this.loader=false; 
             this.$router.push({name:"Home"});//this.$router.go(-1);
            }
           
        }).catch(error => {
        if (!error.response) {
          this.propsmodalalert=true;
        }
      })
 
 },
},
 computed: {
   getpostimgobv(){
    return this.$store.getters.getpostimgobv; 
   },
    getpostobv(){
    return this.$store.getters.getpostobv;
   } 
},
mounted(){
  if(!this.getpostobv.length && this.getpostobv.id!=this.$route.params.id) {
     this.getobvs();
  }
  else {
    this.loader=false;
    this.serdichka=this.getpostobv.serdichka;
  }
    this.$metrika.hit(this.$route.params.id);
}

}
</script>
<style scoped>
.content_images {
 background-color: #ffffff;
 margin-top:50px;
 width: 100%;
height: 250px;
max-width: 600px;
}
.content_images a {
text-decoration: none;
}
.images{
width:100%;
position: relative;
top: 50%;
transform: translateY(-50%);
}
.content_obv {
  background-color: #f5f5f5;
}
.content_s_g_d {
  background-color: #ffffff;
  margin-top:10px;
  padding: 10px 0px 10px 0px;
  border-radius: 5px;
  width:100%;
  max-width: 600px;

}
.content_o_p_i{
  background-color: #ffffff;
  margin-top:10px;
  padding: 10px 0px 10px 0px;
  border-radius: 5px;
  width:100%;
  max-width: 600px;
}
.md{
  margin:5px;
}
.content_o_p_i span {
  font-size: 18px;
}
.content_o_p_i label {
  font-size: 18px;
  color:#333;
}
.content_o_p_i .divlabel {
  margin-left:15px;
  margin-top:5px;
  margin-bottom:5px;
}
.content_o_p_i .divspan {
  margin-top:5px;
  margin-bottom:5px;
}
.content_o_p_i_div {
  font-size: 18px;
  margin: 5px;
  word-wrap:break-word;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;	
  white-space: pre-wrap;
}

.image_s_g_d {
  width: 28px;
  margin-top:2px;
}
.content_s_g_d span {
  font-size:20px;
}
.content_s_g_d label {
  font-size:18px;
}
.content_user{
  background-color: #ffffff;
  margin-top:10px;
  padding: 10px 0px 10px 3px;
  border-radius: 5px; 
}
.user {
  margin-top:20px;
  margin-bottom: 10px;
  text-align: center;
}
.user .user_img {
  color: #666666;
}
.user span{
  color:#000;
  font-size:20px;
}
.zhalob span{
color:#351010;
font-size: 16px;
}
.zhalob label{
color:#ff6600;
}
.zhalob {
padding:7px;
}
.content_head {
height:50px;
left: 0px;
top:0px;
position: fixed;
min-width:100%;
max-width:100%;
background-color:#ffffff;
box-shadow: 0 0 4px 0 rgba(0,0,0,1);
}
.content_head table{
    margin-top:7px;
    width:100%;
}
.imgback {
color:#888888;
margin-left:5px;
margin-right: 10px;
}
.content_tel_mesg{
height:50px;
bottom: 15px;
left:0;
right: 0;
position: fixed;
min-width:100%;
max-width:100%; 
z-index: 1000;
}
.content_tel_mesg table{
  width:98%;
  max-width: 900px;
}
.tel {
background-color:#0b76ef;
width: 9.9em;
padding:5px 10px 5px 0px;
border-radius: 7px;
}
.tel img {
  width: 2em;
}
.tel:hover{
  cursor: pointer;
}
.message:hover{
  cursor: pointer;
}
.tel a {
text-decoration: none;
}
.tel span {
  font-size: 16px;
  color:#fff;
}
.message{
background-color:#4CAF50; 
width: 9.9em;
padding:6px 5px 5px 5px;
border-radius: 7px;
}
.img_msg{
color:#fff;
margin-top:3px;
}
.message span {
  font-size: 20px;
  color:#fff;
}
.title {
  background-color: #fff;
  border-radius: 0px 0px 5px 5px;
  width:100%;
  max-width: 600px;
}
.title h1 {
font-size: 22px;
text-decoration: none;
color:#222;
margin: 0px;
padding: 7px;
word-wrap:break-word;
word-break: break-word;
   -webkit-hyphens: auto;
   -moz-hyphens: auto;
   -ms-hyphens: auto;
   hyphens: auto;
}
.title span{
  font-size:22px;
  padding:7px;
}
.serdichka{
    color:#888888;
    padding: 0px 5px 0px 5px; 
}
.serdichkaactiv{
  color:green;
  padding: 0px 5px 0px 5px; 
}

.serdichka:hover{
cursor:pointer;    
}
.content_head img:hover {
cursor:pointer;
}
</style>

