<template >
<div>
<div  v-for="obv in obvs" :key="obv.id">
  <div class="kart">
    <router-link  :to="{name:'obv', params:{id:obv.id}}">
  <div>
  <table>
      <tr>
          <td>
              <div class="div_kart_img">
                  <clazy-load :src="obv.img_p">
                  <img :src="obv.img_p" class="kart_img" >
                  </clazy-load>
                  </div>
          </td>
          <td width="100%">
            <div class="margin_8-left">
                <label class="title_cbn">
                <b>{{obv.title}}</b>
                </label>
                <label class="sena"> 
                <div v-if="obv.sena==0.0001">Цена не указана</div>
                <div v-else><b>{{obv.sena.toLocaleString('ru-RU')}}</b> сомони</div> 
                </label>
                <label class="opisanie">{{obv.body}}</label>
                <label class="adress">{{obv.adress_text}}</label><br/>
                <label class="data_time">{{obv.dt}}</label>
                
            </div>
          </td>
      </tr>
  </table>
  </div>
 </router-link>
  </div>
</div>
</div>
</template>
<script>
export default {
  components:{
   
  },
     props: {
      obvs: Array,
     }, 
}
</script>
<style scoped>
img[lazy="loading"]{
  display:block;
  width:50px !important;
  height:50px !important;
  margin:0 auto;
  }
</style>