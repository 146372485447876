export default{
    actions: {
      
    },
    mutations: {
    addpostfavorites(state, newpost){
        state.postfavorites=newpost;
    },
    },
    state: {
        postfavorites: [],
    },
    getters:{
        getpostfavorites(state){
            return state.postfavorites;
        }
    }
}