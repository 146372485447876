export default{
    actions: {
      
    },
    mutations: {
    adddialogs(state, newpost){
        state.postdialogs=newpost;
    },
    addkodmessage(state, newpost){
        state.kodmessage=newpost;
    },
    addteluser2message(state, newpost){
        state.teluser2message=newpost;
    },
    addunread(state, newpost){
        state.unread=newpost;
    },
    },
    state: {
        postdialogs: [],
        kodmessage:"",
        teluser2message:"",
        unread:0,
    },
    getters:{
        getdialogs(state){
            return state.postdialogs;
        },
        getkodmessage(state){
            return state.kodmessage;
        },
        getteluser2message(state){
            return state.teluser2message;
        },
        getunread(state){
            return state.unread;
        },
    }
}