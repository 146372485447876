<template >
<div class="content_head">
<table  width="100%" align="center" >
<tr>
<td align="left">
<div onclick="javascript:history.back(); return false;" class="imgback">
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--ep" width="30" height="30" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024">
<path fill="currentColor" d="M685.248 104.704a64 64 0 0 1 0 90.496L368.448 512l316.8 316.8a64 64 0 0 1-90.496 90.496L232.704 557.248a64 64 0 0 1 0-90.496l362.048-362.048a64 64 0 0 1 90.496 0z"></path></svg></div>
</td>
<td align="left"  width="100%">
<div v-if="!titlearr.length">
<div class="title_overflow" >
    <div><h1 class='title-h'>Рубрики</h1></div>
   </div><label class='s-label'>объявление  </label>
</div>
<div v-else>
<div v-for="title in titlearr" :key="title.id" >
   <div class="title_overflow" >
   <div ><h1 class='title-h'>{{title.title}}</h1></div>
   </div><label class='s-label'>{{title.count}} объявление  </label></div>
 </div>
</td>
<td align="center"  >
<router-link to="/settingsearch">
<div class="img"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--majesticons" width="30" height="30" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="currentColor"><path d="M8 5a1 1 0 1 0 0 2a1 1 0 0 0 0-2zM5 6a3 3 0 1 1 6 0a3 3 0 0 1-6 0zm11 5a1 1 0 1 0 0 2a1 1 0 0 0 0-2zm-3 1a3 3 0 1 1 6 0a3 3 0 0 1-6 0zm-5 5a1 1 0 1 0 0 2a1 1 0 0 0 0-2zm-3 1a3 3 0 1 1 6 0a3 3 0 0 1-6 0z"></path>
<path d="M3 6a1 1 0 0 1 1-1h2a1 1 0 0 1 0 2H4a1 1 0 0 1-1-1zm6 0a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H10a1 1 0 0 1-1-1zm-6 6a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm14 0a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1zM3 18a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm6 0a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H10a1 1 0 0 1-1-1z"></path></g></svg></div>
</router-link>
</td>
<td>
</td>	   
</tr>
</table>
</div>
</template>
<script>
export default {
     props: {
          
    titlearr: Array,
   }, 
 
computed:{
getunread(){
    return this.$store.getters.getunread;
   },
}
}
</script>
<style scoped >
.content_head {
height:55px;
left: 0px;
top:0px;
position: fixed;
min-width:100%;
max-width:100%;
background-color:#0b7367;
box-shadow: 0 0 4px 0 rgba(0,0,0,1);
z-index: 1000;
}
 .img {
color:#ffffff;
margin-right:5px;
margin-top:4px;
}
.imgback {
color:#ffffff;
margin-left:5px;
margin-right: 10px;
margin-top:2px;
}
.content_head {
padding-top:5px;
}
.title-h {
font-size: 20px;
text-decoration: none;
margin-bottom: 0px;
margin-top: 0px;
color:#ffffff;
}
.title_overflow{
font-size:18px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
}
.s-label{
font-size: 12px;
color:#ffffff;
}
.content_head a{
text-decoration:none;
}
</style>