<template >
<div class="content_head">
<table  width="100%" >
<tr>
<td align="left"  width="100%">
<div>
<input type = "search" id="search" @keyup.enter="f_search" v-model="keyworld" :placeholder="gorod">
</div>
</td>
<td >
<router-link to="/settingsearch">
<div class="img"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--majesticons" width="30" height="30" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="currentColor"><path d="M8 5a1 1 0 1 0 0 2a1 1 0 0 0 0-2zM5 6a3 3 0 1 1 6 0a3 3 0 0 1-6 0zm11 5a1 1 0 1 0 0 2a1 1 0 0 0 0-2zm-3 1a3 3 0 1 1 6 0a3 3 0 0 1-6 0zm-5 5a1 1 0 1 0 0 2a1 1 0 0 0 0-2zm-3 1a3 3 0 1 1 6 0a3 3 0 0 1-6 0z"></path>
<path d="M3 6a1 1 0 0 1 1-1h2a1 1 0 0 1 0 2H4a1 1 0 0 1-1-1zm6 0a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H10a1 1 0 0 1-1-1zm-6 6a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm14 0a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1zM3 18a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm6 0a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H10a1 1 0 0 1-1-1z"></path></g></svg></div>
</router-link>
</td>
<td>
</td>
</tr>
</table>
</div>
</template>
<script>
export default{
  data (){
    return {
     gorod:"", 
     keyworld:"", 
        
    }
  },
  methods: {
    f_search(){
        this.$store.commit("addkeyworld",this.keyworld);
        this.$router.push({name:'settingsearch'});
    }
},
computed:{
getunread(){
    return this.$store.getters.getunread;
   },
},
mounted(){
  if(localStorage.getItem("gorodtext")){
    this.gorod="Поиск в " + localStorage.getItem("gorodtext");
  }
  else{
    this.gorod="Поиск в Таджикистане";
  }
}
}
</script>
<style scoped>
.content_head {
height:55px;
left: 0px;
top:0px;
position: fixed;
max-width:100%;
min-width: 100%;
background-color:#0b7367;
box-shadow: 0 0 4px 0 rgba(0,0,0,1);
padding-top:6px;
z-index: 1000;
}
.content_head .img {
color:#ffffff;
margin-right:5px;
margin-top:4px;
}
.content_head input {
padding-top:6px;
padding-bottom:6px;
padding-left:10px;
font-size:20px;
margin-left:10px;
border: 1px solid #ffffff;
border-radius: 5px;
width:95%;
background-color: #ffffff;
}
.content_head a{
text-decoration:none;
}
</style>