<template>
    <div>
     <header3 :title="title"/>
     <paddingheader />
      <br/>
       <div  v-if="getpostuser.name" > 
         <span class="hello">Приветь! {{getpostuser.name}}</span>
         <br/>
         <div class="content">
             <table><tr>
             <td align="left" width="50%"><div  :class="[blog==1 ? 'btn_activ' : 'btn_none']" @click="blog=1">Объявления</div></td>
             <td align="right"><div :class="[blog==2 ? 'btn_activ' : 'btn_none']" @click="blog=2">Настройки</div></td>
             </tr></table>
          </div>
         <div  class="blog_obv" v-if="blog==1">
         
            <div class="content_kart" >
                <div class="kart" v-for="obv in getpostuserobvs  " :key="obv.id">
                
                 
              <div  v-if="obv.status==2"  >
                   <div @click="disp_none_block(obv.id)">
                   <table><tr>
                   <td><div class="div_kart_img"><img :src="obv.img_p" class="kart_img" /></div></td>
                   <td width="100%">
                    <div class="margin_10">
                     <div class="title_cbn"><b >{{obv.title}}</b></div>
                     <div class="text_na_proveke">На проверке</div>
                     <div class="sena"> 
                       <div v-if="obv.sena==0.0001">Цена не указана</div>
                       <div v-else><b>{{obv.sena.toLocaleString('ru-RU')}}</b> сомони</div> 
                       </div>
                     <div class="data_time">{{obv.dt}}</div>
                     <div class="block_tel_click" >
                       <table><tr>
                         <td><img class="img_prasmort" src="../assets/image/img_prosmotr.png" ></td>
                         <td width="100%" align="left"><span>{{obv.prasmotr}}</span></td>
                          <td><img class="img_click_tel" src="../assets/image/perm_group_display.png" ></td>
                         <td><span>{{obv.click_tel}}</span></td>
                         </tr></table>
                     </div>
                     </div>
                   </td>
                   </tr></table>
                   </div>
                      <div class="class_setting_obv" v-if="disp==obv.id">
                        <br/>
                        <div class="text_na_proveke">Ваш объявления отправлен на проверку, пожалуйста, подождите.</div>
                        <br/>
                      </div>
                 </div>

                 <div  v-if="obv.status==1"  >
                   <div @click="disp_none_block(obv.id)">
                   <table><tr>
                   <td><div class="div_kart_img"><img :src="obv.img_p" class="kart_img" /></div></td>
                   <td width="100%">
                    <div class="margin_10">
                     <div class="title_cbn"><b >{{obv.title}}</b></div>
                     <div class="text_activno">Активно</div>
                     <div class="sena"> 
                       <div v-if="obv.sena==0.0001">Цена не указана</div>
                       <div v-else><b>{{obv.sena.toLocaleString('ru-RU')}}</b> сомони</div> 
                       </div>
                     <div class="data_time">{{obv.dt}}</div>
                     <div class="block_tel_click" >
                       <table><tr>
                         <td><img class="img_prasmort" src="../assets/image/img_prosmotr.png" ></td>
                         <td width="100%" align="left"><span>{{obv.prasmotr}}</span></td>
                          <td><img class="img_click_tel" src="../assets/image/perm_group_display.png" ></td>
                         <td><span>{{obv.click_tel}}</span></td>
                         </tr></table>
                     </div>
                     </div>
                   </td>
                   </tr></table>
                   </div>
                      <div class="class_setting_obv" v-if="disp==obv.id">
                         <br/>
                        <div align="center"><button class="btns" @click="funcmenuobv(6, obv.id)">ОБНОВИТЬ ДАТУ</button></div>
                        <br/>
                      <table><tr>
                        <td>
                           <router-link class="sil" :to="{name:'obv', params:{id:obv.id}}">
                         <div class="click_setting_obv">
                           <div>
                             <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--mdi" width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                             <path d="M6 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6H6m0 2h7v5h5v11H6V4m2 8v2h8v-2H8m0 4v2h5v-2H8z" fill="currentColor"></path></svg>
                           </div>
                           <div class="text_setting_obv">
                             Посмотреть
                           </div>
                         </div>
                           </router-link>
                         </td><td>
                           <router-link class="sil" :to="{name:'edit', params:{id:obv.id}}">
                          <div class="click_setting_obv">
                           <div>
                             <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--codicon" 
                             width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path d="M13.23 1h-1.46L3.52 9.25l-.16.22L1 13.59L2.41 15l4.12-2.36l.22-.16L15 4.23V2.77L13.23 1zM2.41 13.59l1.51-3l1.45 1.45l-2.96 1.55zm3.83-2.06L4.47 9.76l8-8l1.77 1.77l-8 8z"></path></g></svg>
                           </div>
                           <div class="text_setting_obv">
                             Изменить
                           </div>
                         </div>
                           </router-link>
                        </td><td>
                           <div class="click_setting_obv" @click="funcmenuobv(3, obv.id)">
                           <div>
                             <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--jam"
                              width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M17.398 9.23l1.472-1.472C20.749 8.842 22 10.34 22 12c0 3.314-4.958 5.993-10 6a14.734 14.734 0 0 1-3.053-.32l1.747-1.746c.426.044.862.067 1.303.066h.002c-.415 0-.815-.063-1.191-.18l1.981-1.982c.47-.202.847-.579 1.05-1.049l1.98-1.981A4 4 0 0 1 12.022 16C16.267 15.985 20 13.816 20 12c0-.943-1.022-1.986-2.602-2.77zm-9.302 3.645A4 4 0 0 1 11.993 8C7.775 7.985 4 10.178 4 12c0 .896.904 1.877 2.327 2.644L4.869 16.1C3.134 15.028 2 13.585 2 12c0-3.314 4.984-6.017 10-6c.914.003 1.827.094 2.709.262l-1.777 1.776c-.29-.022-.584-.035-.88-.038c.282.004.557.037.823.096l-4.78 4.779zM21.092 2.707a1 1 0 0 1 0 1.414l-16.97 16.97a1 1 0 1 1-1.415-1.413l16.97-16.97a1 1 0 0 1 1.415 0z" fill="currentColor"></path></svg>
                           </div>
                           <div class="text_setting_obv">
                             Скрыть
                           </div>
                         </div>
                         </td><td>
                           <div class="click_setting_obv" @click="funcmenuobv(4, obv.id)">
                           <div>
                             <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--eva"
                              width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="currentColor"><path d="M21 6h-5V4.33A2.42 2.42 0 0 0 13.5 2h-3A2.42 2.42 0 0 0 8 4.33V6H3a1 1 0 0 0 0 2h1v11a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8h1a1 1 0 0 0 0-2zM10 4.33c0-.16.21-.33.5-.33h3c.29 0 .5.17.5.33V6h-4zM18 19a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V8h12z"></path><path d="M9 17a1 1 0 0 0 1-1v-4a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1zm6 0a1 1 0 0 0 1-1v-4a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1z"></path></g></svg>
                           </div>
                           <div class="text_setting_obv">
                             Удалить
                           </div>
                         </div>
                         </td><td>
                           <div class="click_setting_obv" @click="f_sopitiya(obv.id)">
                           <div>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--jam"
                             width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10zm0-2a8 8 0 1 0 0-16a8 8 0 0 0 0 16zm0-13a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0V8a1 1 0 0 1 1-1zm0 10a1 1 0 1 1 0-2a1 1 0 0 1 0 2z" fill="currentColor"></path></svg>
                           </div>
                           <div class="text_setting_obv">
                             Собития
                           </div>
                         </div>
                          </td>
                        </tr></table>
                      </div>
                 </div>

              <div  v-if="obv.status==3">
                   <div @click="disp_none_block(obv.id)">
                   <table><tr>
                   <td><div class="div_kart_img"><img :src="obv.img_p" class="kart_img" /></div></td>
                   <td width="100%">
                    <div class="margin_10">
                     <div class="title_cbn"><b >{{obv.title}}</b></div>
                     <div class="text_na_proveke">Скрыто</div>
                     <div class="sena"> 
                       <div v-if="obv.sena==0.0001">Цена не указана</div>
                       <div v-else><b>{{obv.sena.toLocaleString('ru-RU')}}</b> сомони</div> 
                       </div>
                     <div class="data_time">{{obv.dt}}</div>
                     <div class="block_tel_click" >
                       <table><tr>
                         <td><img class="img_prasmort" src="../assets/image/img_prosmotr.png" ></td>
                         <td width="100%" align="left"><span>{{obv.prasmotr}}</span></td>
                          <td><img class="img_click_tel" src="../assets/image/perm_group_display.png" ></td>
                         <td><span>{{obv.click_tel}}</span></td>
                         </tr></table>
                     </div>
                     </div>
                   </td>
                   </tr></table>
                   </div>
                      <div class="class_setting_obv" v-if="disp==obv.id">
                        <br/>
                        <div align="center"><button class="btns" @click="funcmenuobv(1, obv.id)">ОПУБЛИКОВАТЬ</button></div>
                        <br/>
                      <table>
                        <tr>
                        <td>
                           <router-link class="sil" :to="{name:'obv', params:{id:obv.id}}">
                          <div class="click_setting_obv">
                           <div>
                             <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--mdi" width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                             <path d="M6 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6H6m0 2h7v5h5v11H6V4m2 8v2h8v-2H8m0 4v2h5v-2H8z" fill="currentColor"></path></svg>
                           </div>
                           <div class="text_setting_obv">
                             Посмотреть
                           </div>
                         </div>
                           </router-link>
                         </td><td>
                          <router-link class="sil" :to="{name:'edit', params:{id:obv.id}}">
                          <div class="click_setting_obv">
                           <div>
                             <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--codicon" 
                             width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path d="M13.23 1h-1.46L3.52 9.25l-.16.22L1 13.59L2.41 15l4.12-2.36l.22-.16L15 4.23V2.77L13.23 1zM2.41 13.59l1.51-3l1.45 1.45l-2.96 1.55zm3.83-2.06L4.47 9.76l8-8l1.77 1.77l-8 8z"></path></g></svg>
                           </div>
                           <div class="text_setting_obv">
                             Изменить
                           </div>
                         </div>
                           </router-link>
                        </td><td>
                           
                           <div class="click_setting_obv" @click="funcmenuobv(4, obv.id)">
                           <div>
                             <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--eva"
                              width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="currentColor"><path d="M21 6h-5V4.33A2.42 2.42 0 0 0 13.5 2h-3A2.42 2.42 0 0 0 8 4.33V6H3a1 1 0 0 0 0 2h1v11a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8h1a1 1 0 0 0 0-2zM10 4.33c0-.16.21-.33.5-.33h3c.29 0 .5.17.5.33V6h-4zM18 19a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V8h12z"></path><path d="M9 17a1 1 0 0 0 1-1v-4a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1zm6 0a1 1 0 0 0 1-1v-4a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1z"></path></g></svg>
                           </div>
                           <div class="text_setting_obv">
                             Удалить
                           </div>
                         </div>
                         </td><td>
                           <div class="click_setting_obv" @click="f_sopitiya(obv.id)">
                           <div>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--jam"
                             width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10zm0-2a8 8 0 1 0 0-16a8 8 0 0 0 0 16zm0-13a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0V8a1 1 0 0 1 1-1zm0 10a1 1 0 1 1 0-2a1 1 0 0 1 0 2z" fill="currentColor"></path></svg>
                           </div>
                           <div class="text_setting_obv">
                             Собития
                           </div>
                         </div>
                          </td>
                        </tr></table>
                      </div>
                 </div>
  
              <div  v-if="obv.status==4">
                   <div @click="disp_none_block(obv.id)">
                   <table><tr>
                   <td><div class="div_kart_img"><img :src="obv.img_p" class="kart_img" /></div></td>
                   <td width="100%">
                    <div class="margin_10">
                     <div class="title_cbn"><b >{{obv.title}}</b></div>
                     <div class="text_na_udalenie">На удалении</div>
                     <div class="sena"> 
                       <div v-if="obv.sena==0.0001">Цена не указана</div>
                       <div v-else><b>{{obv.sena.toLocaleString('ru-RU')}}</b> сомони</div> 
                       </div>
                     <div class="data_time">{{obv.dt}}</div>
                     <div class="block_tel_click" >
                       <table><tr>
                         <td><img class="img_prasmort" src="../assets/image/img_prosmotr.png" ></td>
                         <td width="100%" align="left"><span>{{obv.prasmotr}}</span></td>
                          <td><img class="img_click_tel" src="../assets/image/perm_group_display.png" ></td>
                         <td><span>{{obv.click_tel}}</span></td>
                         </tr></table>
                     </div>
                     </div>
                   </td>
                   </tr></table>
                   </div>
                      <div class="class_setting_obv" v-if="disp==obv.id">
                        <br/>
                          <div class="text_na_udalenie">Будеть удалено через 15 дней</div>
                        <br/>
                        <div align="center"><button class="btns" @click="funcmenuobv(2, obv.id)">ВАССТАНОВИТЬ</button></div>
                        <br/>
                      </div>
                 </div>
  
                  <div  v-if="obv.status==5">
                   <div @click="disp_none_block(obv.id)">
                   <table><tr>
                   <td><div class="div_kart_img"><img :src="obv.img_p" class="kart_img" /></div></td>
                   <td width="100%">
                    <div class="margin_10">
                     <div class="title_cbn"><b >{{obv.title}}</b></div>
                     <div class="text_na_udalenie">Заблокировано</div>
                     <div class="sena"> 
                       <div v-if="obv.sena==0.0001">Цена не указана</div>
                       <div v-else><b>{{obv.sena.toLocaleString('ru-RU')}}</b> сомони</div> 
                       </div>
                     <div class="data_time">{{obv.dt}}</div>
                     <div class="block_tel_click" >
                       <table><tr>
                         <td><img class="img_prasmort" src="../assets/image/img_prosmotr.png" ></td>
                         <td width="100%" align="left"><span>{{obv.prasmotr}}</span></td>
                          <td><img class="img_click_tel" src="../assets/image/perm_group_display.png" ></td>
                         <td><span>{{obv.click_tel}}</span></td>
                         </tr></table>
                     </div>
                     </div>
                   </td>
                   </tr></table>
                   </div>
                      <div class="class_setting_obv" v-if="disp==obv.id">
                        <br/>
                        <div class="text_na_udalenie" >Причина: {{obv.text_no_true}}</div>
                        <br/>
                      <table>
                        <tr>
                        <td>
                           <router-link class="sil" :to="{name:'obv', params:{id:obv.id}}">
                          <div class="click_setting_obv">
                           <div>
                              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--mdi" width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                             <path d="M6 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6H6m0 2h7v5h5v11H6V4m2 8v2h8v-2H8m0 4v2h5v-2H8z" fill="currentColor"></path></svg>
                           </div>
                           <div class="text_setting_obv">
                             Посмотреть
                           </div>
                         </div>
                           </router-link>
                         </td><td>
                          <router-link class="sil" :to="{name:'edit', params:{id:obv.id}}">
                          <div class="click_setting_obv">
                           <div>
                             <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--codicon" 
                             width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path d="M13.23 1h-1.46L3.52 9.25l-.16.22L1 13.59L2.41 15l4.12-2.36l.22-.16L15 4.23V2.77L13.23 1zM2.41 13.59l1.51-3l1.45 1.45l-2.96 1.55zm3.83-2.06L4.47 9.76l8-8l1.77 1.77l-8 8z"></path></g></svg>
                           </div>
                           <div class="text_setting_obv">
                             Изменить
                           </div>
                         </div>
                           </router-link>
                        </td><td>
                           
                           <div class="click_setting_obv" @click="funcmenuobv(4, obv.id)">
                           <div>
                             <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--eva"
                              width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="currentColor"><path d="M21 6h-5V4.33A2.42 2.42 0 0 0 13.5 2h-3A2.42 2.42 0 0 0 8 4.33V6H3a1 1 0 0 0 0 2h1v11a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8h1a1 1 0 0 0 0-2zM10 4.33c0-.16.21-.33.5-.33h3c.29 0 .5.17.5.33V6h-4zM18 19a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V8h12z"></path><path d="M9 17a1 1 0 0 0 1-1v-4a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1zm6 0a1 1 0 0 0 1-1v-4a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1z"></path></g></svg>
                           </div>
                           <div class="text_setting_obv">
                             Удалить
                           </div>
                         </div>
                         </td><td>
                           <div class="click_setting_obv" @click="f_sopitiya(obv.id)">
                           <div>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--jam"
                             width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10zm0-2a8 8 0 1 0 0-16a8 8 0 0 0 0 16zm0-13a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0V8a1 1 0 0 1 1-1zm0 10a1 1 0 1 1 0-2a1 1 0 0 1 0 2z" fill="currentColor"></path></svg>
                           </div>
                           <div class="text_setting_obv">
                             Собития
                           </div>
                         </div>
                          </td>
                        </tr></table>
                      </div>
                 </div>
  
                </div>
            </div>

            <div class="pustoy" v-if="pustoy">
                     <div>
                       <h3>Здесь пока пусто</h3>
                      <span>Но это легко исправить - <br/> разместите первое объявление.</span>
                     <br/> <br/> <br/><router-link class="sil" to="/podat"><button class="btnss"> РАЗМЕСТИТЬ ОБЪЯВЛЕНИЕ</button></router-link>
                      <br/><br/>
                     </div>
             </div>

         </div>
          <div  class="blog_obv" v-if="blog==2">
            <div class="setting_user">
          <div>
          <br/>
          <div v-if="loadsetting" align="center"><Spinner /></div>
          <div class="div_placeholder">{{text_placeholder_name}}</div>
          <input type="text" class="inputt" v-model.trim="name_user" @keyup="f_name" placeholder="Имя">
          <div class="success">{{text_success_name}}</div>
          <button class="btns" @click="f_editnameuser">Изменить</button><br/><br/>
          <div><label>Ваш телефон: {{getpostuser.tel}}</label></div><br/>
          </div> 
              <br/><br/>
              <span @click="viyti()">Выйти из аккаунта</span>
            </div>
          </div>
        </div>
       <div v-else align="center"><Spinner /></div>
       <modal v-if="showModal" @close="close()">
          <span slot="header">Собития</span>
           <div slot="body">
           <div v-if="spinsobitiya" align="center"><Spinner /></div>
            <div v-for="sobit in sobitiya"  :key="sobit.id" >
             <p>{{sobit}}</p>
           </div>
          </div>
      </modal>
       <noconnection :propsmodalalert="propsmodalalert" @emitnoconnection="emitnoconnection" />
    <paddingheader />
    <Footermenu />
    </div>
</template>
<script>
import axios from 'axios';
import noconnection from '../blocks/noconnection'
import header3 from '../blocks/header3';
import paddingheader from '../blocks/paddingheader';
import Footermenu from "../blocks/footermenu";
import Spinner from '../blocks/spinner';
import modal from '../blocks/modal';
export default{
components:{
    Spinner, header3, paddingheader, Footermenu, noconnection, modal

  },
data() {
    return {
        title:"Личный кабинет",
        blog:1,
        propsmodalalert:false,
        disp:0,
        t_disp:0,
        pustoy:false,
        text_placeholder_name:"",
        text_success_name:"",
        name_user:"",
        loadsetting:false,
        showModal: false,
        sobitiya:[],
        spinsobitiya:false,
    }
  },
created(){
    if(!localStorage.getItem("token")){
      if(localStorage.getItem("login")){
        localStorage.removeItem("login");
        this.$router.go(-1);
      }
      else{
       this.$router.push({name:"login"});
      }
    }
    else{
   axios.defaults.headers.common["Authorization"] = "Bearer "+localStorage.getItem("token");
    
   }   
},
methods:{
f_name(){
     if (this.name_user.length > 0){
        this.text_placeholder_name="Имя";
        this.text_success_name="";
     } 
     else this.text_placeholder_name="";
   },
  f_editnameuser(){
   if(this.name_user.length>2){
     this.loadsetting=true;
         axios({
           method: 'post',
            url: 'https://agros.tj/api/editnameuser',
            data: {
            token: localStorage.getItem("token"), nameuser:this.name_user
           }
          })
        .then(res=>{
          if(!res.data.msg){
            if(res.data.status){
               this.$store.commit("addpostuser",res.data.user);
                this.loadsetting=false;
            }
            else{
             localStorage.removeItem("token");
            }
          }
           else{
             localStorage.removeItem("token");
             this.$router.go();
           } 
        }).catch(error => {
        if (!error.response) {
            this.propsmodalalert=true;
        }
      })
   }
   else {
     this.text_success_name="Введите свои имя";
   }
  },
 emitnoconnection(){
   this.propsmodalalert=false;
   this.getuserdata();
 },
disp_none_block(id){
  if(id==this.t_disp){
    this.disp=0;
     this.t_disp=0;
  }
  else{
   this.disp=id;
   this.t_disp=id;
  }
 
},
f_sopitiya(kodobv){
  this.showModal=true;
  this.spinsobitiya=true;
  axios({
           method: 'post',
            url: 'https://agros.tj/api/getsobitiya',
            data: {
            token: localStorage.getItem("token"), id:kodobv
           }
          })
        .then(res=>{
            if(res.data.status){
              this.spinsobitiya=false;
              this.sobitiya=res.data.sobitiya;
            }
        })
},
close(){
this.sobitiya=new Array;
this.showModal=false;
this.spinsobitiya=false;
},
viyti(){
this.$store.commit("addpostuser",new Array);
this.$store.commit("addpostuserobvs",new Array);
 localStorage.removeItem("token");
 this.$router.push({name:"Home"});
  this.$store.commit("addunread",0);
},
funcmenuobv(s, idobv){
  this.$store.commit("addpostuser",new Array);
   try{
         axios({
           method: 'post',
            url: 'https://agros.tj/api/settingobv',
            data: {
            token: localStorage.getItem("token"), status:s, id:idobv
           }
          })
        .then(res=>{
          if(!res.data.msg){
            if(res.data.status){
               this.$store.commit("addpostuser",res.data.user);
               this.$store.commit("addpostuserobvs",res.data.obvs)
               this.disp_none_block(idobv);
               this.chengnewmessage();
            }
            else{
             localStorage.removeItem("token");
            }
          }
           else{
             localStorage.removeItem("token");
             this.$router.go();
           } 
        }).catch(error => {
        if (!error.response) {
            this.propsmodalalert=true;
        }
      })
     } 
     catch (e){alert ("Error");}
   
},
 getuserdata(){
   try{
         axios({
           method: 'post',
            url: 'https://agros.tj/api/useragros',
            data: {
            token: localStorage.getItem("token"),
           }
          })
        .then(res=>{
          if(!res.data.msg){
            if(res.data.status){
               this.$store.commit("addpostuser",res.data.user);
                this.name_user=res.data.user.name;
                this.f_name();
               this.$store.commit("addpostuserobvs",res.data.obvs);
              if(res.data.obvs=="") this.pustoy=true;
            }
            else{
             localStorage.removeItem("token");
            }
          }
           else{
             localStorage.removeItem("token");
             this.$router.go();
           } 
        }).catch(error => {
        if (!error.response) {
            this.propsmodalalert=true;
        }
      })
     } 
     catch (e){alert ("Error");}
   },
   clearvuex(){
     this.$store.commit("addposthomeobvs",new Array)
     this.$store.commit("addpostkategsilka",new Array);
     this.$store.commit("addpostkateg",new Array);
     this.$store.commit("addpostkategobvs",new Array);
     this.$store.commit("addpostpodkateg",new Array);
     this.$store.commit("addpostpodkategobvs",new Array);
     this.$store.commit("addpostobv",new Array);
     this.$store.commit("addpostimgobv",new Array);
     this.$store.commit("adddialogs",new Array);

    },
    chengnewmessage(){
    if(localStorage.getItem("token")){
    axios({
           method: 'post',
            url: 'https://agros.tj/api/chengnewmessage',
            data: {
            token: localStorage.getItem("token"),
           }
          })
        .then(res=>{
            if(res.data.status){           
               this.$store.commit("addunread",res.data.unread);     
             }
           });}}    
},
 computed: {
   getpostuser(){
    return this.$store.getters.getpostuser;
   },
    getpostuserobvs(){
    return this.$store.getters.getpostuserobvs;
   }
},
mounted(){
    if(!this.getpostuser.name){
      if(localStorage.getItem("token")) this.getuserdata();
    } 
    else{
      this.name_user=this.getpostuser.name;
      this.f_name();
    } 
       this.clearvuex();
       this.chengnewmessage();
       this.$metrika.hit("cbn");
}
}
</script>
<style scoped>
.class_setting_obv{
  border:1px solid #ffffff;
  border-top-color: #cccccc;
  text-align: center;
}
.class_setting_obv td{
  width:20%;
}
.click_setting_obv{
  color:#444444;
}
.click_setting_obv:hover{
  cursor: pointer;
  color:green;
}
.text_setting_obv{
  font-size: 12px;
}

.hello {
font-size: 20px;
margin-left:6px;
}
.setting_user{
  padding:10px;
}
.setting_user span{
font-size: 22px;
color:#104490;
}
.setting_user label{
font-size: 20px;
color:#111;
}
.setting_user span:hover{
  color:#ff6600;
  cursor: pointer;
}
.content table{
  width:100%;
}
.btn_activ{
  color:#0066ff;
  font-size: 22px;
  padding:3px 0px 3px 0px;
  border:2px solid #ffffff;
   border-bottom-color:#0066ff;
}
.btn_activ:hover{
  cursor: pointer;
}
.btn_none{
  font-size: 22px;
  padding:3px 0px 3px 0px;
  border:2px solid #ffffff;
}
.btn_none:hover{
  cursor: pointer;
}
.text_na_proveke{
  font-size: 18px;
  color:#ff6600;
}
.text_activno{
  font-size: 18px;
  color:#0066ff;
}
.text_na_udalenie{
  font-size: 18px;
  color:red;
}
.margin_10{
  margin-left: 10px;
}
.img_prasmort {
  width:30px;
}
.img_click_tel{
  width: 15px;
}
.block_tel_click {
  width:7.6em;
  margin-left:-3px;
}
.pustoy{
  text-align: center;
  color:#666;
  font-size: 20px;
}
</style>
