<template>
<div> 
 
 <paddingheader />
<Homemenu />

<Avtobaza />

<div class="silezhie">Свежие</div>
<div class="content_kart">
 <div v-for="gethome in getposthomeobvs " :key="gethome.id">
        <Karta  :obvs="gethome" /> 
  </div>
  </div>
<div class="spin" align="center">
  <div  v-if="loader" >
   <Spinner />
 </div>
 <div v-else>
 <button v-if="pei" class="btns" @click="getindexobvs()">ПОКАЗАТЬ ЕЩЕ</button>
 </div>
</div>

<div ref="observer" class="observer"></div>
  
<noconnection :propsmodalalert="propsmodalalert" @emitnoconnection="emitnoconnection" />
<paddingheader />
<Header />
<Updateapp v-if="version" :props_app_link="props_app_link" />
<Footermenu />
 </div>

 
</template>
<script>
import Footermenu from "../blocks/footermenu";
import Spinner from '../blocks/spinner';
import paddingheader from '../blocks/paddingheader';
import Header from '../blocks/header';
import Homemenu from '../blocks/homemenu';
import noconnection from '../blocks/noconnection';
import Karta from '../blocks/karta';
import Avtobaza from '../blocks/avtobaza';
import Updateapp from '../blocks/updateapp';
import axios from 'axios';
export default{
  components:{
   Updateapp, Header, paddingheader, Spinner, Footermenu, noconnection, Karta, Homemenu, Avtobaza
   
  },
  data (){
    return {
        propsmodalalert:false,
        loader:false,
        loadaxios:true,
        pei:true,
        props_app_link:"",
        version:false,
        id_version:1,
        
    }
  },
  methods:{
   clearvuex(){
     this.$store.commit("addpostkategsilka",new Array);
     this.$store.commit("addpostkateg",new Array);
     this.$store.commit("addpostkategobvs",new Array);
     this.$store.commit("addpostpodkateg",new Array);
     this.$store.commit("addpostpodkategobvs",new Array);
     this.$store.commit("addpostuser",new Array);
     this.$store.commit("addpostuserobvs",new Array)
     this.$store.commit("addpostobv",new Array);
     this.$store.commit("addpostimgobv",new Array);
     this.$store.commit("addpostfiltirobvs",new Array); 

    },
    getindexobvs(){
      if(this.loadaxios){
      this.loader=true;
      this.loadaxios=false; 
    axios({
           method: 'post',
            url: 'https://agros.tj/api/gethomeobvs',
            data: {
            gorod: localStorage.getItem("gorod"),
            limit:this.getposthomeobvs.length,
            version:this.id_version,
           }
          })
        .then(res=>{
            if(res.data.status){           
               this.$store.commit("addposthomeobvs",res.data.obvs)
               this.loader=false;   
               this.loadaxios=true; 
                if(res.data.version){
                 this.version=true;
                 this.props_app_link=res.data.app_link;
               }      
             }
            else{
             this.pei=false;
             this.loader=false; 
            }
           }).catch(error => {
           if (!error.response) {
           this.propsmodalalert=true;
          }
        })
      }
     
    },
    
  emitnoconnection(){
   this.propsmodalalert=false;
   this.loadaxios=true; 
   this.getindexobvs();
  },
  chengnewmessage(){
    if(localStorage.getItem("token")){
    axios({
           method: 'post',
            url: 'https://agros.tj/api/chengnewmessage',
            data: {
            token: localStorage.getItem("token"),
           }
          })
        .then(res=>{
            if(res.data.status){           
               this.$store.commit("addunread",res.data.unread);     
             }
           });
          }
        }
     },
  
  computed: {
  getposthomeobvs(){
    return this.$store.getters.getposthomeobvs
   } 
  },
 created(){
    if(localStorage.getItem("token")){
      axios.defaults.headers.common["Authorization"] = "Bearer "+localStorage.getItem("token");
    }  
},
   mounted(){
    if(!this.getposthomeobvs.length){
        this.getindexobvs();
     }
    this.clearvuex();
    this.chengnewmessage();
    
    const options = {
    rootMargin: '700px',
    threshold: 1.0
     }
     const callback = (entries)=> {
     if(entries[0].isIntersecting){
        this.getindexobvs();
        this.pei=false;
      }
     };
      const observer = new IntersectionObserver(callback, options);
     observer.observe(this.$refs.observer);
     this.$metrika.hit("home");
    }, 
   
}
</script>
<style scoped>
.silezhie{
  background-color: #f5f5f5;
  padding: 5px;
  font-size: 22px;
}
</style>