<template >
<div>
<div class="kart" v-for="obv in obvs" :key="obv.id">
    <div v-if="obv.sel">
    <router-link  :to="{name:'obv', params:{id:obv.id}}">
  <div>
  <table>
      <tr>
          <td>
              <div class="div_kart_img"><img :src="obv.img_p" class="kart_img" /></div>
          </td>
          <td width="100%">
            <div class="margin_8-left">
                <label class="title_cbn">
                <b >{{obv.title}}</b>
                </label>
                <label class="sena"> 
                <div v-if="obv.sena==0.0001">Цена не указана</div>
                <div v-else><b>{{obv.sena.toLocaleString('ru-RU')}}</b> сомони</div> 
                </label>
                <label class="opisanie">{{obv.body}}</label>
                <label class="adress">{{obv.adress_text}}</label><br/>
                <label class="data_time">{{obv.dt}}</label>
            </div>
          </td>
      </tr>
  </table>
  </div>
 </router-link>
 <div class="del_favorites" @click="obv.sel=!obv.sel , f_del_favorites(obv.id) " >
    <svg  width="1.5em" height="1.5em" viewBox="0 0 24 24">
    <path d="M18.36 19.78L12 13.41l-6.36 6.37l-1.42-1.42L10.59 12L4.22 5.64l1.42-1.42L12 10.59l6.36-6.36l1.41 1.41L13.41 12l6.36 6.36z" fill="currentColor"></path></svg>   
 </div>
</div>
</div>
</div>
</template>
<script>
export default {
     props: {
      obvs: Array,
     },
     methods:{
      f_del_favorites(id){
        this.$emit('emitfavorites', id);  
      }   
     } 
}
</script>
<style scoped>
.del_favorites{
    float:right;
    margin-top:-35px;
    padding: 3px;
}
.del_favorites:hover{
cursor: pointer;
color:red;
}
</style>