<template>
<div class="content" align="center">
  <div class="main">
   <router-view ></router-view>
  </div>
</div>
</template>



<script>
import axios from "axios"
export default {
  components:{
    
  },
  created(){
   if(localStorage.getItem("token")){
   axios.defaults.headers.common["Authorization"] = "Bearer "+localStorage.getItem("token");
   }
  },
  mounted(){
   
  }
};
</script>

<style>
#app {
font-family: Helvetica, Arial, sans-serif;
margin:0px;
}
body,html{
background-color:#f5f5f5;
margin:0px;
height: 100%;
/*font-family: "Times New Roman";*/
}
div, span, label, a, h1,h2,h3, input, textarea {
  font-family: Helvetica, Arial, sans-serif;
}
.btns {
  background: none;
  background-color:#0b76ef;
  padding:14px 12px;
  border:none;
  border-radius: 5px;
  font-size: 16px;
  color:#ffffff;
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.2);
}
.btns:hover {
background-color:#1381fd;
 box-shadow: 0 0 4px 0 rgba(0,0,0,0); 
 cursor:pointer;
}
.btnss {
  background: none;
  background-color:#4CAF50;
  padding:14px 12px;
  border:none;
  border-radius: 5px;
  font-size: 16px;
  color:#ffffff;
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.2);
}
.btnss:hover {
background-color:#309533;
 box-shadow: 0 0 4px 0 rgba(0,0,0,0); 
 cursor:pointer;
}
.btnb {
  background: none;
  background-color:#ef9f0b;
  padding:14px 12px;
  margin-right: 10px;
  border:none;
  border-radius: 5px;
  font-size: 16px;
  color:#ffffff;
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.2);
}
.btnb:hover {
background-color:#e7b615;
 box-shadow: 0 0 4px 0 rgba(0,0,0,0); 
 cursor:pointer;
}
.btnnoback {
  background: none;
  padding:10px;
  border:none;
  color:#0b76ef;
}
.btnnoback:hover {
 cursor:pointer;
}
.font_20 {
  font-size:20px;
}
.spin{
  height: 100px;
  background-color: #f5f5f5;
}
.font_22 {
  font-size:22px;
}
.margin_5{
  margin-left:5px;
}
.margin_12{
  margin:12px;
}
.margin_8-left{
  margin-left: 8px;
  margin-top:1px;
  margin-bottom: 1px;
  margin-right: 2px;
}
input{
font-family: "Arial";
}
input[type=number]::-webkit-inner-spin-button {
-webkit-appearance: none;
}
input[type=number] { 
-moz-appearance: textfield;
appearance: textfield;
margin: 0; 
}
textarea:focus, button:focus, input:focus{
outline: none;
}
.margin_div{
  height: 20px;
  background-color: #f5f5f5;
}
.content {
  margin:0px;
  padding:0px;
}
.main {
  max-width: 900px;
  min-width: 250px;
  text-align: left;
  margin:0px 6px 0px 6px ;
  background-color: #ffffff;
}
.title_cbn{
font-size:18px;
word-wrap:break-word;
word-break: break-word;
   -webkit-hyphens: auto;
   -moz-hyphens: auto;
   -ms-hyphens: auto;
   hyphens: auto;	
}

.sil{
  text-decoration: none;
}
.colorgreen{
  color: #28862b;
}
.kart a{
  text-decoration: none;
  color:#000000;
}
.kart a:hover{
  text-decoration: none;
  color:#ff6600;
  cursor: pointer;
}
.kart {
display:block;
background-color:#ffffff;
border-radius: 10px;
max-width: 444px;
min-width: 200px;
display: inline-block;
margin: 2px;
}
.content_kart{
 background-color: #f5f5f5; 
 margin:-2px;
 
}
/*.kart table{
  border-collapse: collapse;
}*/
.kart .data_time{
font-size:15px;
Color:#000000;
float:left;
padding-top:5px;
}
.kart .adress{
font-size:16px;
Color:#000000;
}
.kart .sena{
font-size:17px;
Color:#333333;
}
.kart .opisanie{
word-wrap: break-word;
font-size:16px;
Color:#777777;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
word-wrap:break-word;
word-break: break-word;
-webkit-hyphens: auto;
-moz-hyphens: auto;
-ms-hyphens: auto;
hyphens: auto;	
}
svg:hover {
  cursor: pointer;
}
.div_kart_img{
width:160px;
height:153px;
background-color:#f5f5f5;
}
.kart_img{
width:100%;
border-radius:8px 0px 0px 8px ;
}

/***** */
.custom-checkbox {
z-index: -1;
opacity: 0;
}
.custom-checkbox+label {
display: inline-flex;
align-items: left;
user-select: none;
font-size:20px;
}
.custom-checkbox+label::before {
content: '';
display: inline-block;
width: 0.9em;
height: 0.9em;
flex-shrink: 0;
flex-grow: 0;
border: 1px solid #0066ff;
border-radius: 0.25em;
margin-right: 0.2em;
background-repeat: no-repeat;
background-position: center center;
background-size: 50% 50%;
}
.custom-checkbox:not(:disabled):not(:checked)+label:hover::before {
border-color: #b3d7ff;
}
.custom-checkbox:not(:disabled):active+label::before {
background-color: #b3d7ff;
border-color: #b3d7ff;
}
.custom-checkbox:focus+label::before {
box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-checkbox:focus:not(:checked)+label::before {
border-color: #80bdff;
}
.custom-checkbox:checked+label::before {
border-color: #0b76ef;
background-color: #0b76ef;
background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox:disabled+label::before {
background-color: #e9ecef;
}
/**     */
.custom-radio {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .custom-radio+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
  }
  .custom-radio+label::before {
    content: '';
    display: inline-block;
    width: 0.8em;
    height: 0.8em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 50%;
    margin-right: 0.4em;
    margin-top: -0.1em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
  .custom-radio:not(:disabled):not(:checked)+label:hover::before {
    border-color: #b3d7ff;
  }
  .custom-radio:not(:disabled):active+label::before {
    background-color: #b3d7ff;
    border-color: #b3d7ff;
  }
  .custom-radio:focus+label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  .custom-radio:focus:not(:checked)+label::before {
    border-color: #80bdff;
  }
  .custom-radio:checked+label::before {
    border-color: #0b76ef;
    background-color: #0b76ef;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  }
  .custom-radio:disabled+label::before {
    background-color: #e9ecef;
  }
</style>
