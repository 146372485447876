<template >
<div class="content_head" align="center">
<table  width="100%">
<tr>
<td align="left">
<div onclick="javascript:history.back(); return false;" class="imgback">
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--ep" width="30" height="30" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024">
<path fill="currentColor" d="M685.248 104.704a64 64 0 0 1 0 90.496L368.448 512l316.8 316.8a64 64 0 0 1-90.496 90.496L232.704 557.248a64 64 0 0 1 0-90.496l362.048-362.048a64 64 0 0 1 90.496 0z"></path></svg></div>
</td>
<td align="left"  width="100%">
<div ><div class="title_overflow"><h1 class='title-h'>{{title}}</h1></div></div>
</td>	   
</tr>
</table>
</div>
</template>
<script>
export default {
     props: {
          
    title: String
   }  
}
</script>
<style scoped>
.content_head {
height:55px;
left: 0px;
top:0px;
position: fixed;
min-width:100%;
max-width:100%;
background-color:#0b7367;
box-shadow: 0 0 4px 0 rgba(0,0,0,1);
}
.title-h {
font-size: 20px;
text-decoration: none;
color:#ffffff;
}
.imgback {
color:#ffffff;
margin-left:5px;
margin-right: 10px;
margin-top:2px;
}
.title_overflow{
font-size:20px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
}
</style>