<template>
 <div class="margin5 ">
  
  <paddingheader />
  <br/>
  <div v-show="displayfiltir">
   <div>
     <div class="div_placeholder">{{text_placeholder_title}}</div>
     <input type="text" class="inputt" v-model.trim="title_obv" @keyup="f_title" placeholder="Ключевые слова">
  </div> 
  <br/>
  <kpk @emitpodkateg="emitpodkateg" @emitkateg="emitkateg"
         :kate="propskate"
         :podkate="propspodkate"
  />
 <br/>
   <gorod :goro="gorod" :allgrord="allgrord" @emittextgorod="emittextgorod" @emitgorod="emitgorod" />
  <br/>
  <div class="sena_ot_do">
   <table >
       <tr>
           <td>
             <div class="div_placeholder">{{text_placeholder_sena_ot}}</div>
             <input type="number" class="inputt" v-model.trim="sena_ot" @keyup="f_sena_ot" placeholder="Цена, от">
           </td>
           <td>
             <div class="div_placeholder">{{text_placeholder_sena_do}}</div>
             <input type="number" class="inputt" v-model.trim="sena_do" @keyup="f_sena_do" placeholder="Цена, до">
           </td>
       </tr>
       </table>    
  </div>
   <br/>
   <br/>  
  </div>
   <div class="content_rezult">
   <div align = "center" v-if="noobv"><h2>Ничего не найдено!</h2></div>
  <div class="content_kart">
    <div v-for="get in getpostfiltirobvs " :key="get.id">
        <Karta  :obvs="get" /> 
    </div>
  </div>
   <div align="center" v-if="loading" >
      <Spinner />
  </div>
  <div v-else align="center">
  <button v-if="pei" class="btns" @click="getfiltirobv()">ПОКАЗАТЬ ЕЩЕ</button>
  </div>
   </div>
   <div ref="observer" class="observer"></div>
<div v-if="!loading">
<div class="k_search" align="center" v-if="btnfiltir">
    <button class="btns" @click="getfiltirobv">ПОКАЗАТЬ ( {{rezultcount}} объявлений )</button>
</div>
</div>  
   <noconnection :propsmodalalert="propsmodalalert" @emitnoconnection="emitnoconnection" />
  <div class="content_head" align="center">
    <table  width="100%">
    <tr>
      <td align="left">
       <div onclick="javascript:history.back(); return false;" class="imgback">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--ep" width="30" height="30" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024">
          <path fill="currentColor" d="M685.248 104.704a64 64 0 0 1 0 90.496L368.448 512l316.8 316.8a64 64 0 0 1-90.496 90.496L232.704 557.248a64 64 0 0 1 0-90.496l362.048-362.048a64 64 0 0 1 90.496 0z"></path></svg></div>
      </td>
      <td align="left"  width="100%">
      <div ><div class="title_overflow"><h1 class='title-h'>{{title}}</h1></div></div>
     </td>
     <td><div @click="f_display_filtir()"><div class="img"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--majesticons" width="30" height="30" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="currentColor"><path d="M8 5a1 1 0 1 0 0 2a1 1 0 0 0 0-2zM5 6a3 3 0 1 1 6 0a3 3 0 0 1-6 0zm11 5a1 1 0 1 0 0 2a1 1 0 0 0 0-2zm-3 1a3 3 0 1 1 6 0a3 3 0 0 1-6 0zm-5 5a1 1 0 1 0 0 2a1 1 0 0 0 0-2zm-3 1a3 3 0 1 1 6 0a3 3 0 0 1-6 0z"></path>
      <path d="M3 6a1 1 0 0 1 1-1h2a1 1 0 0 1 0 2H4a1 1 0 0 1-1-1zm6 0a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H10a1 1 0 0 1-1-1zm-6 6a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm14 0a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1zM3 18a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm6 0a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H10a1 1 0 0 1-1-1z"></path></g></svg></div></div></td>	   
    </tr>
    </table>
</div>
  </div> 
</template>

<script>
import Spinner from '../blocks/spinner';
import paddingheader from '../blocks/paddingheader';
import noconnection from '../blocks/noconnection';
import kpk from '../blocks/kpk';
import gorod from '../blocks/gorod';
import Karta from '../blocks/karta';
import axios from "axios";
export default {
 
  components:{
     paddingheader, kpk, gorod, noconnection, Spinner, Karta
  },
  data() {
   
     return {
      title: "Фильтр поиска",
      kateg:"",
      podkateg:"",
      title_obv:"",
      propsmodalalert:false,
      gorod:"",
      gorodtext:"",
      text_placeholder_title:"",
      sena_ot:"",
      sena_do:"",
      text_placeholder_sena_ot:"",
      text_placeholder_sena_do:"",
      rezultcount:0,
      loading:true,
      pauza:1000,
      pauz:200,
      timer:"",
      noobv:false,
      displayfiltir:true,
      btnfiltir:false,
      p:false,
      propskate:"",
      propspodkate:"",
      allgrord:"",
      pei:false,

    };
  },
  methods: {
   f_display_filtir(){
      this.$store.commit("addpostfiltirobvs",new Array); 
      this.$store.commit("addpostobv",new Array);
      this.$store.commit("addpostimgobv",new Array);
      this.displayfiltir=true;
      this.sena_ot="";
      this.sena_do="";
      this.title_obv="";
      this.getcountobv();

    },
   emitkateg (kategid) {
     this.kateg=kategid;
     console.log(this.kateg);
     if(this.kateg=="") this.podkateg="";
     this.getcountobv();

   },
   emitpodkateg (podkategid) {
     this.podkateg=podkategid;
      console.log(this.podkateg);
      this.getcountobv();
   },
   emitgorod (gorodid) {
     this.gorod=gorodid;
      console.log(this.gorod);
      this.getcountobv();
   },
   emitnoconnection(){
      this.propsmodalalert=false; 
      this.getcountobv();
   },
    emittextgorod (gorodidtext) {
     this.gorodtext=gorodidtext;
     if(!gorodidtext)this.allgrord="true";
      console.log(this.gorodtext);
   },
   f_title(){
     if (this.title_obv.length > 0) {
       this.text_placeholder_title="Ключевые слова";
     }
     else this.text_placeholder_title="";
    clearTimeout(this.timer);
    this.timer = setTimeout ( () => {
    this.getcountobv(); 
    }, this.pauza);
   },
   f_sena_ot(){
     if (this.sena_ot > 0) this.text_placeholder_sena_ot="Цена, от";
     else this.text_placeholder_sena_ot="";
     clearTimeout(this.timer);
    this.timer = setTimeout ( () => {
    this.getcountobv(); 
    }, this.pauza);
   },
   f_sena_do(){
     if (this.sena_do > 0) this.text_placeholder_sena_do="Цена, до";
     else this.text_placeholder_sena_do="";
      clearTimeout(this.timer);
    this.timer = setTimeout ( () => {
    this.getcountobv(); 
    }, this.pauza);
   },
   getcountobv(){
    clearTimeout(this.timer);
    this.timer = setTimeout ( () => {
     this.loading=true;
     this.noobv=false;
     axios({
           method: 'post',
            url: 'https://agros.tj/api/getcountobv',
            data: {
            gorod: this.gorod,
            keyworld:this.title_obv,
            kateg:this.kateg,
            podkateg:this.podkateg,
            senaot:this.sena_ot,
            senado:this.sena_do,
           }
          })
        .then(res=>{
            if(res.data.status){           
               this.loading=false;
               this.btnfiltir=true;
               this.rezultcount=res.data.countobv;   
               this.p=true;
             }
            else{
             this.loading=false;
            }
           }).catch(error => {
           if (!error.response) {
           this.propsmodalalert=true;
          }
        })
        }, this.pauz);
      },
 getfiltirobv(){
    if(this.p){
     this.loading=true;
     this.noobv=false;
     axios({
           method: 'post',
            url: 'https://agros.tj/api/getfiltirobv',
            data: {
            gorod: this.gorod,
            keyworld:this.title_obv,
            kateg:this.kateg,
            podkateg:this.podkateg,
            senaot:this.sena_ot,
            senado:this.sena_do,
            limit:this.getpostfiltirobvs.length,
           }
          })
        .then(res=>{
            if(res.data.status){           
               this.loading=false;
               this.btnfiltir=false;
                   if(this.rezultcount > 20){this.pei=true;}
                   if(!this.gorod){
                   localStorage.removeItem('gorodtext');
                   localStorage.removeItem('gorod');
                   }
                   else{
                   localStorage.setItem('gorodtext', this.gorodtext);
                   localStorage.setItem('gorod', this.gorod);
                   }
                   
             
                this.displayfiltir=false;
                this.$store.commit("addpostkategsilka",new Array);
                this.$store.commit("addpostkateg",new Array);
                this.$store.commit("addpostkategobvs",new Array);
                this.$store.commit("addpostpodkateg",new Array);
                this.$store.commit("addpostpodkategobvs",new Array);
                this.$store.commit("addposthomeobvs",new Array);
                this.$store.commit("addpostfiltirobvs",res.data.obvs);
                if(!this.sena_ot && !this.sena_do && !this.title_obv){
                
                  if(this.podkateg){
                     this.$router.push({name:"pk", params:{id:this.podkateg}});
                  }
                 else if(this.kateg){
                      this.$router.push({name:"k", params:{id:this.kateg}});
                 }
                 else{
                      this.$router.push({name:"Home"});
                 }
                } 
             }
            else{
              this.p=false;
              if(this.getpostfiltirobvs=="") this.noobv=true;
              this.loading=false;
              this.pei=false;
            }
           }).catch(error => {
           if (!error.response) {
           this.propsmodalalert=true;
          }
        })
      }
    }
  },
   computed: {
    getpostfiltirobvs(){
    return this.$store.getters.getpostfiltirobvs
   },
     getpostkateg(){
    return this.$store.getters.getpostkateg; 
   },
    getpostpodkateg(){
    return this.$store.getters.getpostpodkateg; 
   },
   getkeyworld(){
    return this.$store.getters.getkeyworld; 
   },
  },
  mounted(){   
     this.$store.commit("addpostobv",new Array);
     this.$store.commit("addpostimgobv",new Array);
     if(localStorage.getItem("gorod")) {
       this.gorod=String(localStorage.getItem("gorod"));
     }
     else{
       this.allgrord="true";
     }
     this.getpostkateg.forEach((element) => {
       if(element.key) this.propskate=String(element.key);
      });
      this.getpostpodkateg.forEach((element) => {
       if(element.key) this.propspodkate=String(element.key);
      })
     if(this.getpostfiltirobvs==""){
        this.getcountobv();
     } 
     else{
       this.displayfiltir=false;
       this.loading=false; 
     }
     if(this.getkeyworld){
       this.title_obv=this.getkeyworld;
       this.f_title();
       this.$store.commit("addkeyworld","");
     }
     const options = {
     rootMargin: '500px',
     threshold: 1.0
     }
     const callback = (entries)=> {
     if(entries[0].isIntersecting){
       if(this.getpostfiltirobvs !=""){
        this.getfiltirobv();
        this.pei=false;
     }
        
      }
     };
      const observer = new IntersectionObserver(callback, options);
     observer.observe(this.$refs.observer);
  },
};
</script>

<style scoped>
.content_rezult{
  background-color: #f5f5f5;
  margin-left:-5px;
  margin-right: -5px;
}
.k_search{
display:block-inline;
bottom:20px;
margin: 0px;
left:0px;
position: fixed;
min-width:100%;
max-width:100%;
}
.margin5{
  margin: 5px;
}
.sena_ot_do table{
width: 100%;
}
.sena_ot_do table td{
width: 50%;
}
.content_upload_img{
height:150px;
}

.inputt {
  width:98%;
  border: 1px solid #ffffff;
  border-bottom-color: #000000;
  padding:5px 0px 5px 3px;
  font-size: 20px;
}
.content_head {
height:55px;
left: 0px;
top:0px;
padding-top:0px;
position: fixed;
min-width:100%;
max-width:100%;
background-color:#0b7367;
box-shadow: 0 0 4px 0 rgba(0,0,0,1);
}
.imgback {
color:#ffffff;
margin-left:5px;
margin-right: 10px;
margin-top:2px;
}
.title-h {
font-size: 20px;
text-decoration: none;
color:#ffffff;
}
.content_head .img {
color:#ffffff;
margin-right:5px;
margin-top:2px;
}

.title_overflow{
font-size:20px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
}

</style>
