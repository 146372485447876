<template>
    <div>
       
      <paddingheader />
        <div  v-if="getpostsilka.length" >
          <div class="silkakateg">
           <div v-for="silka in getpostsilka" :key="silka.id">
             <router-link :to="{name:'pk', params:{id:silka.idpodkateg}}">
               <div><span>{{silka.textpodkateg}}</span> <label>{{silka.count}}</label> </div>
             </router-link>
            </div>
          </div>
         </div>
          <div class="content_kart">
          <div v-for="getkateg  in getkategobvs " :key="getkateg.id">
          <Karta  :obvs="getkateg" /> 
          </div>
          </div>
         
        <div ref="observer" class="observer"></div>

      <div class="spin" align="center">
       <div  v-if="loader" >
         <br/> <br/>   
         <Spinner />
         <br/> <br/>   
        </div>
        <div v-else>
           <button v-if="pei" class="btns" @click="getobvs();">ПОКАЗАТЬ ЕЩЕ</button>
        </div>
        </div>
       <noconnection :propsmodalalert="propsmodalalert" @emitnoconnection="emitnoconnection" />
    <paddingheader />
    <header2 :titlearr="getpostkateg" />
    <Footermenu />
    </div>
</template>
<script>
import axios from 'axios';
import noconnection from '../blocks/noconnection'
import header2 from '../blocks/header2';
import paddingheader from '../blocks/paddingheader';
import Footermenu from "../blocks/footermenu";
import Spinner from '../blocks/spinner';
import Karta from '../blocks/karta';
export default{
components:{
    Spinner, header2, paddingheader, Footermenu, noconnection, Karta

  },
data() {
    return {
        loader:false,
        propsmodalalert:false,
        loadaxios:true,
        pei:true, 
         
    }
  },
created(){
    
     
},
methods:{
emitnoconnection(){
   this.propsmodalalert=false;
   if(!this.getpostsilka.length){
     this.getsilka();
     this.getobvs();
   }
   else {
     this.loadaxios=true; 
     this.getobvs();
   }
   //this.getsilkapodkateg();
   
 },
 getsilka(){
    this.loader=true;
    try{
         axios({
           method: 'post',
            url: 'https://agros.tj/api/getsilka',
            data: {
            k:this.$route.params.id,
            gorod: localStorage.getItem("gorod"),
           }
          })
        .then(res=>{
          
            if(res.data.status){
               this.$store.commit("addpostkategsilka",res.data.silka);
               this.$store.commit("addpostkateg",res.data.kateg)
               this.loader=false;
            }
            else{
             alert("error");
            }
           
        }).catch(error => {
        if (!error.response) {
            this.propsmodalalert=true;
        }
      })
     } 
     catch (e){alert ("Error");}
 },
 getobvs(){
  if(this.loadaxios){
   this.loader=true;
    this.loadaxios=false; 
         axios({
           method: 'post',
            url: 'https://agros.tj/api/getkategobvs',
            data: {
            k:this.$route.params.id,
            limit:this.getkategobvs.length,
            gorod: localStorage.getItem("gorod"),
           }
          })
        .then(res=>{
          
            if(res.data.status){
               this.$store.commit("addpostkategobvs",res.data.obvs);  
               this.loader=false; 
               this.loadaxios=true; 
            }
            else{
             this.loader=false; 
             this.pei=false;
            }
           
        }).catch(error => {
        if (!error.response) {
            this.propsmodalalert=true;
        }
      })
 }
 },
     clearvuex(){
     this.$store.commit("addpostpodkateg",new Array);
     this.$store.commit("addpostpodkategobvs",new Array);
     this.$store.commit("addpostobv",new Array);
     this.$store.commit("addpostimgobv",new Array);
     this.$store.commit("addpostfiltirobvs",new Array); 
    },
},
 computed: {
  getpostsilka(){
    return this.$store.getters.getpostkategsilka;
   },
   getpostkateg(){
    return this.$store.getters.getpostkateg; 
   },
    getkategobvs(){
    return this.$store.getters.getpostkategobvs;
   } 
},
mounted(){
  this.clearvuex();
  if(!this.getpostsilka.length && this.getpostkateg.key!=this.$route.params.id) {
     this.$store.commit("addpostkategsilka",new Array);
     this.$store.commit("addpostkateg",new Array);
     this.$store.commit("addpostkategobvs",new Array);
     this.getsilka();
     this.getobvs();
  }
  const options = {
    rootMargin: '700px',
    threshold: 1.0
     }
     const callback = (entries)=> {
     if(entries[0].isIntersecting){
        this.getobvs();
        this.pei=false;
      }
     };
      const observer = new IntersectionObserver(callback, options);
     observer.observe(this.$refs.observer);
     this.$metrika.hit(this.$route.params.id);
    },


}
</script>
<style scoped>
.silkakateg{
background-color: #f5f5f5; 
padding-top:20px;
height:4.6em;
width:100%;
overflow-x: auto;
overflow-y: auto;
white-space: nowrap;
}
.silkakateg a{
text-decoration: none;
}
.silkakateg div{
background-color: #ffffff;
border-radius: 10px;
margin: 3px 5px 3px 1px;
padding:6px  5px;
display: inline-block;
}
.silkakateg span{
color: #0b76ef;
font-size: 20px;
}
.silkakateg span:hover{
color: #ff6600;
}
.silkakateg label{
color: #121213;
font-size: 18px;
}
</style>

