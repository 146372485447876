export default{
    actions: {
      
    },
    mutations: {
    addpostpodkategobvs(state, newpost){
        if(newpost.length) state.postobvs.push(newpost);
        else state.postobvs=newpost;
        
    },
    addpostpodkateg(state, newpost){
        state.podkateg=newpost;
    }
    },
    state: {
        postobvs: [],
        podkateg:[]
    },
    getters:{
        getpostpodkategobvs(state){
            return state.postobvs
        }, 
        getpostpodkateg(state){
            return state.podkateg
        }, 
    }
}