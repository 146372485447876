<template>
 <div class="margin5">
  <header3 :title="title"/>
  <paddingheader />
  <kpk :propskategsuccess="propskategsuccess"
       :propspodkategsuccess="propspodkategsuccess"
        :kate="propskate"
        :podkate="propspodkate"
       @emitpodkateg="emitpodkateg" @emitkateg="emitkateg"/>
  <div>
     <div class="div_placeholder">{{text_placeholder_title}}</div>
     <input type="text" class="inputt" v-model.trim="title_obv" @keyup="f_title" placeholder="Заголовок">
     <div class="success">{{text_success_title}}</div>
  </div> 
  <div v-if="blockformtextarea">   
     <div class="div_placeholder">{{text_placeholder_body}}</div>
    
      <resizable-textarea ref="resize">
      <textarea ref="textarea" rows="1" class="textareat" v-model.trim="body" @keyup="f_body" placeholder="Описание"></textarea>
    </resizable-textarea>
     <div class="success">{{text_success_body}}</div>
  </div>  
  <div>
     <div class="div_placeholder">{{text_placeholder_sena}}</div>
     <div class="content_sena">
       <table><tr>
         <td class= "td_sena">
            <input type="number" class="inputt" v-model.trim="sena" @keyup="f_sena" placeholder="Цена">
         </td>
         <td>
           <div class = "text_somoni" >сомони</div>
         </td>
         </tr></table>
     </div>
     <div class="success">{{text_success_sena}}</div>
     <div class="content_torg_obmen">
      <div class="checkbox">
      <input class="custom-checkbox" v-model="torg"  type="checkbox" id="torg" name="torg" value="indigo">
      <label for="torg">торг</label>
      </div>
      <div class="checkbox">
      <input class="custom-checkbox"  v-model="obmen" type="checkbox" id="obmen" name="obmen" value="indig55o">
      <label for="obmen">обмен</label>
      </div>
     </div>
  </div>  
  <br/> 
   <gorod :goro="gorod" :propsgorodsuccess="propsgorodsuccess" @emittextgorod="emittextgorod" @emitgorod="emitgorod" />
   <div>
     <div class="div_placeholder">Тел</div>
     <div class="div_tel" >{{tel}}</div>
  </div>
  <div>
    <br/>
     <div class="div_placeholder">{{text_placeholder_name}}</div>
     <input type="text" class="inputt" v-model.trim="name_user_obv" @keyup="f_name" placeholder="Имя">
     <div class="success">{{text_success_name}}</div>
  </div> 
  <div class="content_chat">
  <div class="checkbox">
      <input class="custom-checkbox" v-model="chat"  type="checkbox" id="chat" name="chat" value="indigo">
      <label for="chat">Отключить чат</label>
  </div>
  </div>
  <br/>
   <div class="content_upload_img">
    <div class="div_upload_image">
      <div v-for='p in posts' :key="p.id">
        <img :src =  p.image   class="img_load" style="image-orientation: flip;" >
          <svg class="del_img" @click="delete_img(p.id)" viewBox="0 0 24 24"><path d="M18.36 19.78L12 13.41l-6.36 6.37l-1.42-1.42L10.59 12L4.22 5.64l1.42-1.42L12 10.59l6.36-6.36l1.41 1.41L13.41 12l6.36 6.36z" fill="currentColor"></path></svg>
        </div>
      </div>
      <div align="center">
        <image-uploader
        :maxWidth="600"
        :quality="0.8"
        :preview="true"
        accept="image/*"
        :className="['fileinput', { 'fileinput--loaded': hasImage }]"
        capture="environment"
        :debug="1"
        doNotResize="gif"
        :autoRotate= rotate
        outputFormat="verbose"
        @input="setImage"
        @onUpload="startImageResize"
        @onComplete="endImageResize"
       >
        <label v-if="img_btn" for="fileInput" slot="upload-label">
           <div class="btnuploadimg" v-if="img_btn_upload">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--ri" width="40" height="40" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
          <path fill="currentColor" d="M21 15v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2zm.008-12c.548 0 .992.445.992.993v9.349A5.99 5.99 0 0 0 20 13V5H4l.001 14l9.292-9.293a.999.999 0 0 1 1.32-.084l.093.085l3.546 3.55a6.003 6.003 0 0 0-3.91 7.743L2.992 21A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016zM8 7a2 2 0 1 1 0 4a2 2 0 0 1 0-4z"></path></svg>
           <div><span>Добавить фото</span></div>
           </div>
           <div v-else  class="spin_bnt_uploadimg">
           <Spinner />
           </div>
        </label>
      </image-uploader>
     </div>
   <div class="success">{{text_success_img}}</div>
  </div>
   <br/> 
   <br/> 
   <div align="center">
       <table>
           <tr>
               <td> <button class="btnb" @click="otmenit">ОТМЕНИТЬ</button></td>
               <td> <button class="btns" @click="Opublikovat">ИЗМЕНИТЬ</button></td>
           </tr>
       </table>
   </div>
   <br/><br/><br/>
   <modalupload v-if="startupload" >
   <div slot="body">
     <table><tr>
       <td><Spinner /></td>
       <td width="100%">
         <div class="font_20">Подождите, идёть загрузка...</div>
         <div class="font_20">{{idicator_upload_image}}</div>
       </td>
       </tr></table>
      
   </div>
   </modalupload>
   <noconnection :propsmodalalert="propsmodalalert" @emitnoconnection="emitnoconnection" />
  </div> 
</template>

<script>
import ResizableTextarea from '../blocks/ResizableTextarea'
import Spinner from '../blocks/spinner';
import header3 from '../blocks/header3';
import modalupload from '../blocks/modalupload';
import paddingheader from '../blocks/paddingheader';
import noconnection from '../blocks/noconnection';
import kpk from '../blocks/kpk';
import gorod from '../blocks/gorod';
import axios from "axios";
export default {
 
  components:{
    Spinner, header3, paddingheader, kpk, gorod, modalupload, noconnection, ResizableTextarea
  },
  data() {
   
     return {
      hasImage: false,
      image: null,
      posts:[
           
      ],
      id_obv:0,
      idicator_upload_image:"",
      img_btn_upload: true,
      img_btn:true,
      name: 'WebsymbolHeartEmpty',
      title: "Изменить объявление",
      kateg:"",
      podkateg:"",
      propskate:"",
      propspodkate:"",
      propskategsuccess:"",
      propspodkategsuccess:"",
      blockformtextarea:false,
      title_obv:"",
      body:"",
      sena:"",
      torg:false,
      obmen:false,
      name_user_obv:"",
      propsmodalalert:false,
      tel:"",
      chat:false,
      gorod:"",
      gorodtext:"",
      propsgorodsuccess:"",
      text_placeholder_title:"",
      text_success_title:"",
      text_placeholder_body:"",
      text_success_body:"",
      text_success_img:"",
      text_placeholder_sena:"",
      text_success_sena:"",
      text_placeholder_name:"Имя", 
      text_success_name:"",
      showpodkateg:false,
      status_success:true,
      startupload:false,
      img_upload_1:"",
      img_upload_2:"",
      img_upload_3:"",
      img_upload_4:"",
      img_upload_5:"",
      img_upload_6:"",
      image_p:"",
      i:0,
      ochirad:false,
      sUsrAg:"",
      rotate:true,
    };
  },
  methods: {
    setImage: function(output) {
      this.hasImage = true;
      this.image = output;
      const newpost={
       id: Date.now(),
       image: output.dataUrl
      };
      this.posts.push(newpost);
      this.text_success_img="";
      if(this.posts.length>5){
        this.img_btn=false;
      }
    },

    delete_img(index){
      console.log(index);
      this.posts = this.posts.filter(p => p.id !== index);
      if(this.posts.length<6){
        this.img_btn=true;
      }
    }, 
    startImageResize: function (){
      this.img_btn_upload= false;
    }, 
    endImageResize: function (){
      this.img_btn_upload= true;
   },
   emitkateg (kategid) {
     this.kateg=kategid;
     console.log(this.kateg);
   },
   emitpodkateg (podkategid) {
     this.podkateg=podkategid;
      console.log(this.podkateg);
   },
   emitgorod (gorodid) {
     this.gorod=gorodid;
      console.log(this.gorod);
   },
   emitnoconnection(){
    if(this.ochirad){
      this.propsmodalalert=false;
       this.Opublikovat();
    }
    else{
       this.$router.go();
    }
   },
    emittextgorod (gorodidtext) {
     this.gorodtext=gorodidtext;
      console.log(this.gorodtext);
   },
   f_title(){
     if (this.title_obv.length > 0) {
       this.text_placeholder_title="Заголовок";
       this.text_success_title="";
     }
     else this.text_placeholder_title="";
   },
   
   f_body(){
     if (this.body.length > 0){
      this.text_placeholder_body="Описание";
      this.text_success_body="";
     } 
     else this.text_placeholder_body="";
   },
   f_sena(){
     if (this.sena > 0) this.text_placeholder_sena="Цена";
     else this.text_placeholder_sena="";
   },
   f_name(){
     if (this.name_user_obv.length > 0){
        this.text_placeholder_name="Имя";
        this.text_success_name="";
     } 
     else this.text_placeholder_name="";
   },
   check(){
     this.status_success=true;
     if(this.kateg==""){
       this.propskategsuccess="Выберите категорию";
       this.status_success=false;
     }else{
       if(this.podkateg==""){
         this.propspodkategsuccess="Выберите подкатегорию";
         this.status_success=false;
       }
     }
     if(this.title_obv.length<2){
       this.status_success=false;
       this.text_success_title="Заголовок не может быть пустым";
     }
     if(this.title_obv.length>60){
       this.status_success=false;
       this.text_success_title="Максималньная длина 60-символ";
     }
     if(this.body.length>1000){
       this.status_success=false;
       this.text_success_body="Максималньная длина 1000-символ";
     }
     if(this.body.length<3){
       this.status_success=false;
       this.text_success_body="Описание не может быть пустым";
     }
      if(this.gorod==""){
       this.propsgorodsuccess="Выберите свой город из списка";
       this.status_success=false;
     }
     if(this.name_user_obv.length<2){
       this.status_success=false;
       this.text_success_name="Введите свои имя";
     }
     if(this.name_user_obv.length>60){
       this.status_success=false;
       this.text_success_name="Максималньная длина 60-символ";
     }
     return this.status_success;
   },
   Opublikovat() {
     if(this.check()){
       this.startupload=true;
       this.ochirad=true;
       if(this.posts.length>0){
         this.i=0;
         this.upload_image();
         
       }
       else{
         this.image_p="https://imgagros.ru/storage/img/7788/4549/2021-10-29_064306.jpg";
          this.senddata();
       }
     }
    
    //this.idicator_upload_image="455455" + this.name_user_obv;
    
     
   },
   upload_image(){
    
    if(this.posts[this.i].image.length>200){
     try{
         axios({
           method: 'post',
            url: 'https://imgagros.ru/api/upload',
            data: {
            image:this.posts[this.i].image,
           },
           onUploadProgress: function( progressEvent ) {
              this.idicator_upload_image = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) ) + "%" + "  " + "(" + (this.i+1) +"/"+ this.posts.length+")";
           }.bind(this)
          })
        .then(res=>{
if(res.data.status){
if (this.img_upload_1==""){
this.img_upload_1=res.data.answer; 
}
else if (this.img_upload_2==""){	
this.img_upload_2=res.data.answer; 
}
else if (this.img_upload_3==""){	
this.img_upload_3=res.data.answer; 
}
else if (this.img_upload_4==""){	
this.img_upload_4=res.data.answer; 
}
else if (this.img_upload_5==""){	
this.img_upload_5=res.data.answer;
}else{
this.img_upload_6=res.data.answer;
}
this.i++;
if (this.i < this.posts.length) {
  this.upload_image();
}else{
  if(this.img_upload_1!==""){
    this.idicator_upload_image ="";
    this.cropImage();
  }
}
}
else{
alert("ERROR");
}
}).catch(error => {
if (!error.response) {
    // network error
    this.startupload=false;
    this.propsmodalalert=true;
}
})    
} 
catch (e){ alert ("Error");} 

} 
else {
 if (this.img_upload_1==""){
this.img_upload_1=this.posts[this.i].image; 
}
else if (this.img_upload_2==""){	
this.img_upload_2=this.posts[this.i].image; 
}
else if (this.img_upload_3==""){	
this.img_upload_3=this.posts[this.i].image; 
}
else if (this.img_upload_4==""){	
this.img_upload_4=this.posts[this.i].image; 
}
else if (this.img_upload_5==""){	
this.img_upload_5=this.posts[this.i].image;
}else{
this.img_upload_6=this.posts[this.i].image;
}
this.i++;
if (this.i < this.posts.length) {
  this.upload_image();
}else{
  if(this.img_upload_1!==""){
    this.idicator_upload_image ="";
    this.cropImage();
  }
}   
}
      
},





cropImage(){
 try{
         axios({
           method: 'post',
            url: 'https://imgagros.ru/api/crop',
            data: {
            image_p:this.img_upload_1,
           }
          })
        .then(res=>{
            if(res.data.status){
              this.image_p=res.data.answer;
               this.senddata();
            }
            else{
             this.idicator_upload_image=false;
            }
        }).catch(error => {
        if (!error.response) {
            this.startupload=false;
            this.propsmodalalert=true;
        }
      })    
     } 
     catch (e){
         alert ("Error");
     } 
   },


otmenit(){
this.$router.push({name:"cbn"});
},


senddata(){
   axios.defaults.headers.common["Authorization"] = "Bearer "+localStorage.getItem("token");
     if(this.sena=="") this.sena=0.0001;
      try{
         axios({
           method: 'post',
            url: 'https://agros.tj/api/edit',
            data: {
            id:this.id_obv,
            title:this.title_obv,
            name:this.name_user_obv,
            body:this.body,
            sena:this.sena,
            torg:this.torg,
            obmen:this.obmen,
            adress_index:this.gorod,
            adress_text:this.gorodtext,
            kateg:this.kateg,
            podkateg:this.podkateg,
            chat:this.chat,
            imgp:this.image_p,
            img1:this.img_upload_1,
            img2:this.img_upload_2,
            img3:this.img_upload_3,
            img4:this.img_upload_4,
            img5:this.img_upload_5,
            img6:this.img_upload_6,
            token:localStorage.getItem("token"),

           }
          })
        .then(res=>{
          if(!res.data.message){
            if(res.data.status){
              this.idicator_upload_image=true;
              this.$store.commit("addpostuser",new Array);
               this.$router.go(-1);
            }
            else{
             this.startupload=false;
             alert("Произошла ошибка попробуйте снова");
            }
          }
          else{
              localStorage.removeItem("token");
              this.$router.go();
          } 
              
        }).catch(error => {
        if (!error.response) {
            this.startupload=false;
            this.propsmodalalert=true;
        }
      })    
     } 
     catch (e){
         alert ("Error");
     }
  },



geteditobv(){
    this.startupload=true;
   try{
         axios({
           method: 'post',
            url: 'https://agros.tj/api/geteditobv',
            data: {
            token:localStorage.getItem("token"), id:this.$route.params.id
           }
          })
        .then(res=>{
            if(res.data.status){
               this.id_obv=res.data.obv.id;
               this.propskate=String(res.data.obv.kateg);
               this.propspodkate=String(res.data.obv.podkateg);
               this.title_obv=res.data.obv.title;
               this.f_title();
               this.body=res.data.obv.body;
               this.f_body();
               this.blockformtextarea=true;
               this.sena=res.data.obv.sena;
               if(this.sena==0.0001) this.sena="";
               this.f_sena();
               if(res.data.obv.torg=="0")this.torg=false;
               else this.torg=true;
               if(res.data.obv.obmen=="0")this.obmen=false;
               else this.obmen=true;
               this.gorod=String(res.data.obv.adress_index);
               this.tel=res.data.obv.tel;
               this.name_user_obv=res.data.obv.name;
               this.f_name;
               if(res.data.obv.chat=="0")this.chat=false;
               else this.chat=true;
               if(res.data.obv.img_1){
               const newPost ={
                   id: 1,
                   image:res.data.obv.img_1
               };
               this.posts.push(newPost); 
               }
               if(res.data.obv.img_2){
               const newPost ={
                   id: 2,
                   image:res.data.obv.img_2
               };
               this.posts.push(newPost); 
               }
               if(res.data.obv.img_3){
               const newPost ={
                   id: 3,
                   image:res.data.obv.img_3
               };
               this.posts.push(newPost); 
               }
               if(res.data.obv.img_4){
               const newPost ={
                   id: 4,
                   image:res.data.obv.img_4
               };
               this.posts.push(newPost); 
               }
               if(res.data.obv.img_5){
               const newPost ={
                   id: 5,
                   image:res.data.obv.img_5
               };
               this.posts.push(newPost); 
               }
               if(res.data.obv.img_6){
               const newPost ={
                   id: 6,
                   image:res.data.obv.img_6
               };
               this.posts.push(newPost); 
               }
               this.startupload=false;
              
            }
            else{
             this.$router.push({name:"cbn"});
            }
        }) 
     } 
     catch (e){
         alert ("Error");
     }  
 }


},
 

  mounted(){
   window.scrollTo(0,0);  
   this.sUsrAg = navigator.userAgent;
   if (this.sUsrAg.indexOf("Chrome") > -1) {
     this.rotate=false;
   }
   if (this.sUsrAg.indexOf("SamsungBrowser") > -1) {
     this.rotate=true;
   } 
  },
  created(){
      //alert(this.$route.params.id);
    if(!localStorage.getItem("token")){
       this.$router.push({name:"login"});
    }
    else{
     axios.defaults.headers.common["Authorization"] = "Bearer "+localStorage.getItem("token");
     this.geteditobv();
    }
     
},
};
</script>

